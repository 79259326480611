import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useMixpanel } from "react-mixpanel-browser";
import { CreateOrder, CompleteOrder } from "./mutations";
import { useState, useContext } from "react";
import { parseNetworkError } from "./helpers";
import CheckoutHelper from "../../../helpers/CheckoutHelper";
import useInputState from "../../../hooks/useInputState";
import VideoContext from "../../../context/VideoContext";

const calculateShipping = gql`
  query calculateShipping($quantity: Int!, $destinationStateId: String!, $productId: String!) {
    calculateShipping(
      quantity: $quantity
      destinationStateId: $destinationStateId
      productId: $productId
    )
  }
`;

export function useCheckoutReview({
  variantData,
  productData,
  product,
  address,
  billingData: { token, paymentRequestButton },
  video,
  disclaimerData: { employer, occupation, employerAddress, retired },
  shippingData: { email, customerNote },
  donationData,
  closeSidebar
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useInputState(1);
  const mixpanel = useMixpanel();

  const { data: shipping } = useQuery(calculateShipping, {
    variables: { quantity, destinationStateId: address.stateId, productId: product.id }
  });

  const [{ watchTime, videoLength }] = useContext(VideoContext);
  const calculateProductPrice = () => {
    if (productData.isDonation) {
      return donationData;
    }

    let { variant } = variantData;

    if (!productData) {
      return 0;
    } else if (productData && !variant) {
      return productData.price;
    }

    return variant.price ? variant.price : Math.min(...productData.variants.map(v => v.price));
  };

  const productPrice = calculateProductPrice() * quantity;
  const productTax = productPrice * 0; // TODO: calculate this with TaxJar
  const shippingPrice = (shipping && shipping.calculateShipping) || 0;
  const productTotal = productPrice + productTax + shippingPrice;
  const formattedTotal = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2
  }).format(productTotal);

  const [createOrder] = useMutation(CreateOrder);
  const [completeOrder] = useMutation(CompleteOrder);
  const onBuyClick = async () => {
    setLoading(true);
    try {
      await CheckoutHelper.createOrder(
        {
          product,
          video,
          address,
          email,
          token,
          employer,
          employerAddress,
          retired,
          occupation,
          customerNote,
          quantity,
          donationAmount: productData.isDonation ? donationData : undefined,
          watchTime,
          videoLength,
          paymentRequestButton
        },
        {
          createOrder,
          completeOrder,
          mixpanel
        }
      );
      setLoading(false);
      closeSidebar();
    } catch (e) {
      const error = parseNetworkError(e);
      if (error.includes("out_of_stock")) {
        setErrorMessage(`${product.name} is out of stock.`);
      } else {
        setErrorMessage(error);
      }
      setLoading(false);
    }
  };

  return {
    price: productPrice,
    tax: productTax,
    total: formattedTotal,
    shipping: shippingPrice,
    onBuyClick,
    errorMessage,
    quantity,
    setQuantity,
    loading
  };
}
