import React from "react";
import { Dialog, Button } from "@blueprintjs/core";
import styles from "./PurchaseCompleteDialog.module.scss";

export default function PurchaseCompleteDialog({ product, isOpen, onClose }) {
  return (
    <Dialog style={{ width: 350 }} isOpen={isOpen} onClose={onClose}>
      <div className={styles.purchaseComplete}>
        <img src={require("./stars.svg")} className={styles.stars} alt="" />

        <h1>
          {product.isDonation ? "Thank you for your donation!" : "Congrats on Your Purchase!"}
        </h1>

        {!product.isDonation && (
          <p>
            <strong>{product.name}</strong>
            <br />
            has been ordered!
          </p>
        )}

        <p>
          A confirmation email has been sent to you. {product.owner.name} will send you tracking
          information separately.
        </p>

        <Button
          className={styles.returnToVideo}
          text="Return to Video"
          onClick={onClose}
          large
          fill
        />
      </div>
    </Dialog>
  );
}
