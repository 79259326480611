import React from "react";
import classNames from "classnames";
import styles from "./Checkout.module.scss";

export default function CheckoutInfo({ children, style, small = false, legible = false }) {
  return (
    <div
      style={style}
      className={classNames(styles.checkoutInfo, {
        [styles.small]: small,
        [styles.legible]: legible
      })}
    >
      {children}
    </div>
  );
}
