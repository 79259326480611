import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { useMixpanel } from "react-mixpanel-browser";
import Page from "../../layouts/Page";
import Player from "../../molecules/Player/Player";
import VideoInfo from "./VideoInfo";
import VideoHeader from "./VideoHeader";
import { CheckoutDrawer } from "../../molecules/CheckoutDrawer/CheckoutDrawer";
import { Spinner, Card } from "@blueprintjs/core";
import VideoContext from "../../context/VideoContext";
import CheckoutHelper from "../../helpers/CheckoutHelper";
import PurchaseCompleteDialog from "../../molecules/PurchaseCompleteDialog/PurchaseCompleteDialog";
import { NotFound } from "../Error";

import { GetVideo } from "./queries";

import styles from "./Watch.module.scss";
import BgImage from "./BgImage";

function Watch({ match }) {
  const mixpanel = useMixpanel();
  const { videoId } = match.params;
  const { loading: videoLoading, data: videoData } = useQuery(GetVideo, {
    variables: { id: videoId }
  });

  const videoContextState = useState({ watchTime: "0", videoLength: "0" });
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [purchaseCompleteOpen, setPurchaseCompleteOpen] = useState(false);

  if (videoLoading) {
    return (
      <Page
        title="Watch"
        style={{ display: "flex", height: "100vh", alignItems: "center", justifyContent: "center" }}
      >
        <Spinner />
      </Page>
    );
  }

  const onBuyClick = () => {
    setCheckoutOpen(true);
    CheckoutHelper.trackPurchaseStarted(
      {
        video,
        product,
        watchTime: videoContextState[0].watchTime,
        videoLength: videoContextState[0].videoLength
      },
      { mixpanel }
    );
  };

  const video = videoData && videoData.videos[0];
  const product = video && video.product;
  const { owner: productOwner } = product || {};
  const preference = productOwner && productOwner.preference;
  const domainUrl = product && product.owner.domainUrl;
  const appUrl = process.env.REACT_APP_ZIPLINE_WEB_URI;

  // Ensure that brand can only see videos of their own products on their domain
  // (if set). Redirect to 404 as a fallback

  const isValidPage =
    !!video &&
    (window.location.origin === appUrl ||
      (domainUrl && domainUrl.includes(window.location.origin)) ||
      (domainUrl && domainUrl.includes(window.location.host)));

  if (!isValidPage) {
    return <NotFound />;
  }

  /** Inline styles */
  const headerStyle =
    preference && preference.headingColor ? { color: preference.headingColor } : {};
  const textStyle = preference && preference.textColor ? { color: preference.textColor } : {};

  return (
    <Page title={video.name}>
      <BgImage preference={preference} />
      <div className={styles.page}>
        <VideoContext.Provider value={videoContextState}>
          <Card className={styles.card}>
            <VideoHeader
              headerStyle={headerStyle}
              textStyle={textStyle}
              video={video}
              preference={preference}
            />
            <Player video={video} onBuyClick={onBuyClick} checkoutOpen={checkoutOpen} />
          </Card>
          <VideoInfo
            textStyle={textStyle}
            headerStyle={headerStyle}
            video={video}
            onBuyClick={onBuyClick}
          />

          <CheckoutDrawer
            video={video}
            product={product}
            isOpen={checkoutOpen}
            onClose={() => setCheckoutOpen(false)}
            onCheckout={() => setPurchaseCompleteOpen(true)}
          />
          <PurchaseCompleteDialog
            product={video.product}
            isOpen={purchaseCompleteOpen}
            onClose={() => setPurchaseCompleteOpen(false)}
          />
        </VideoContext.Provider>
      </div>
    </Page>
  );
}

export default withRouter(Watch);
