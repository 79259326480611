import gql from "graphql-tag";

export const GetShippingInfo = gql`
  query GetShippingInfo {
    accounts {
      id

      shippingMethods {
        id
        name
        street1
        city
        state
        postalCode
        isDefault
      }
    }
  }
`;
