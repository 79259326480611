import React from "react";
import styles from "./CheckoutReview.module.scss";
import { Icon, Spinner, Callout, Intent } from "@blueprintjs/core";
import {
  useCheckoutState,
  BILLING_PAGE,
  VARIANT_PAGE,
  POLITICAL_PAGE,
  SHIPPING_PAGE,
  DONATION_PAGE
} from "../../../molecules/CheckoutDrawer/CheckoutDrawer";
import { useCheckoutReview } from "./hooks";
import { getVariants, formatAddress } from "./helpers";
import CheckoutStep from "../../../molecules/Checkout/CheckoutStep";
import CheckoutActions from "../../../molecules/Checkout/CheckoutActions";
import CheckoutButton from "../../../molecules/Checkout/CheckoutButton";
import CheckoutContent from "../../../molecules/Checkout/CheckoutContent";

function CheckoutReview({ setStep, requirements, closeSidebar }) {
  const state = useCheckoutState();
  const getRequirementData = name =>
    requirements.includes(name) ? state.requirements[name].data : {};

  const { product, video } = state;
  const variantData = getRequirementData(VARIANT_PAGE);
  const disclaimerData = getRequirementData(POLITICAL_PAGE);
  const billingData = getRequirementData(BILLING_PAGE);
  const shippingData = getRequirementData(SHIPPING_PAGE);
  const donationData = getRequirementData(DONATION_PAGE);

  const address = formatAddress(shippingData);
  const {
    price,
    shipping,
    total,
    onBuyClick,
    errorMessage,
    quantity,
    setQuantity,
    loading
  } = useCheckoutReview({
    variantData,
    productData: product,
    product: !variantData.variant ? product : { ...product, ...variantData.variant },
    address,
    billingData,
    video,
    disclaimerData,
    shippingData,
    donationData,
    closeSidebar
  });

  return (
    <CheckoutStep className={styles.reviewPurchase}>
      <div className={styles.mainHeading}>
        <span>Review Purchase</span>
      </div>
      <CheckoutContent>
        {errorMessage && (
          <Callout intent={Intent.DANGER} style={{ fontSize: "12px", color: "#ac0303" }}>
            {errorMessage}
          </Callout>
        )}
        {requirements.map((elem, index) => {
          switch (elem) {
            case DONATION_PAGE:
              return (
                <ReviewStep
                  step={index}
                  onStepClick={() => setStep(index)}
                  title="Donation"
                  content={
                    <p>
                      {Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                        donationData
                      )}
                    </p>
                  }
                />
              );
            case VARIANT_PAGE:
              return (
                <ReviewStep
                  step={index}
                  onStepClick={() => setStep(index)}
                  title="Product Selection"
                  content={
                    <p>
                      {product.name}
                      {` - ${getVariants(variantData.variant.selectedValues)}`}
                    </p>
                  }
                />
              );
            case POLITICAL_PAGE:
              return (
                <ReviewStep
                  step={index}
                  onStepClick={() => setStep(index)}
                  title="Disclaimer Agreement"
                  content={
                    <p>
                      {disclaimerData.occupation}, {disclaimerData.employer}.
                    </p>
                  }
                />
              );
            case BILLING_PAGE:
              return (
                <ReviewStep
                  step={index}
                  onStepClick={() => setStep(index)}
                  title="Payment Information"
                  content={
                    <p>
                      {billingData.brand} Bank Card ({billingData.last4})
                    </p>
                  }
                />
              );
            case SHIPPING_PAGE:
              return (
                <ReviewStep
                  step={index}
                  onStepClick={() => setStep(index)}
                  title="Contact Shipping"
                  content={
                    <p>
                      {shippingData.firstName} {shippingData.lastName} <br />{" "}
                      {shippingData.streetName}
                    </p>
                  }
                />
              );

            default:
              return null;
          }
        })}
        <div className={styles.finalReceipt}>
          <span>Price Breakdown</span>
          <div className={styles.flexRow}>
            <div className={styles.leftColumn}>
              <p>Price</p>
            </div>
            <div className={styles.rightColumn}>
              <p>
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2
                }).format(price)}
              </p>
            </div>
          </div>

          <div className={styles.flexRow}>
            <div className={styles.leftColumn}>
              <p>Tax</p>
            </div>

            <div className={styles.rightColumn}>
              <p>Included</p>
            </div>
          </div>

          <div className={styles.flexRow}>
            <div className={styles.leftColumn}>
              <p>Shipping</p>
            </div>

            <div className={styles.rightColumn}>
              <p>
                {(shipping &&
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2
                  }).format(shipping)) ||
                  "Included"}
              </p>
            </div>
          </div>

          <div className={styles.grandTotal}>
            <div className={styles.flexRow}>
              <div className={styles.leftColumn}>
                <p>Total</p>
              </div>
              <div className={styles.rightColumn}>
                <p className={styles.boldText}>{total}</p>
              </div>
            </div>
          </div>
        </div>
      </CheckoutContent>
      <CheckoutActions>
        {!product.isDonation && !billingData.paymentRequestButton && (
          <div className={styles.leftCol}>
            <QuantityButton onChange={setQuantity} quantity={quantity} />
          </div>
        )}
        <div className={styles.rightCol}>
          <CheckoutButton primary onClick={onBuyClick}>
            {loading && <Spinner size={Spinner.SIZE_SMALL} className={styles.loader} />}
            {!loading && !product.isDonation && `Buy for ${total}`}
            {!loading && product.isDonation && `Donate ${total}`}
          </CheckoutButton>
        </div>
      </CheckoutActions>
    </CheckoutStep>
  );
}

function ReviewStep({ title, content, step, onStepClick }) {
  return (
    <div className={styles.informationBox} onClick={onStepClick}>
      <div className={styles.detailInfo}>
        <span>
          {step + 1}. {title}
        </span>
        {content}
      </div>
      <Icon
        className={styles.arrowIcon}
        icon="chevron-right"
        iconSize="16"
        color="rgb(144, 155, 173)"
      />
    </div>
  );
}

function QuantityButton({ onChange, quantity = 1, maxQauntity = 10 }) {
  const options = [...Array(maxQauntity).keys()].map(keys => keys + 1);
  return (
    <div className={styles.quantitySelector}>
      <select onChange={onChange}>
        {options.map(value => (
          <option value={value}>{value}</option>
        ))}
      </select>
      <button className={styles.quantityBtn}>
        {quantity} QTY <Icon icon="chevron-down" />
      </button>
    </div>
  );
}

export default CheckoutReview;
