import gql from "graphql-tag";

export const GetVideo = gql`
  query GetVideo($id: String!) {
    videos(id: $id) {
      id
      name
      url
      live
      thumbnailUrl
      createdAt
      scheduleStatus
      scheduleStartTime
      opportunity {
        isDiscontinued
        expiresAt
        applicationEvents {
          applicant {
            id
          }
          status
        }
      }
      owner {
        id
        name
        picture
        bio
        politicalDisclaimer
      }
      product {
        id
        name
        description
        price
        isDonation
        stock
        opportunities {
          amount
          isPercentage
          isDiscontinued
        }
        owner {
          id
          name
          bio
          picture
          fixedFee
          variableFee
          domainUrl
          isActive
          preference {
            headingColor
            textColor
            backgroundColor
            backgroundImage
            brandLogo
          }
          tokenizationKey
          isNmiAccount
        }
        images {
          url
        }
        categories {
          id
          name
        }
        options {
          id
          name
          values {
            id
            name
          }
        }
        variants {
          id
          name
          price
          stock
          images {
            url
          }
          selectedValues {
            id
            name
            option {
              id
              name
            }
          }
        }
      }
    }
  }
`;
