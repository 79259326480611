import React from "react";

import { Icon } from "../../atoms/Icon";

import styles from "./SavedPaymentInfo.module.scss";

function SavedPaymentInfo({ last4, type }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <Icon name="Credit-Card" color="#8a9ba8" size={20} />
      </div>
      <div className={styles.detailsWrapper}>
        <p className={styles.detail}>{type}</p>
        <p className={styles.detail}>•••• {last4}</p>
      </div>
    </div>
  );
}

export default SavedPaymentInfo;
