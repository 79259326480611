import React from "react";

import { Icon as BlueprintIcon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import IcomoonReact, { iconList } from "icomoon-react";
import iconSet from "./selection.json";

import classNames from "classnames";

export const ZipdingIconNames = iconList(iconSet);
export const BlueprintIconNames = Object.values(IconNames).sort();

function Icon({ name, onClick = () => {}, color, size = 16, className, wrapperClassName }) {
  if (ZipdingIconNames.includes(name)) {
    return (
      <span className={classNames("icon-wrapper", wrapperClassName)} onClick={onClick}>
        <IcomoonReact
          icon={name}
          color={color}
          size={size}
          className={className}
          iconSet={iconSet}
        />
      </span>
    );
  } else if (BlueprintIconNames.includes(name)) {
    return (
      <BlueprintIcon
        icon={name}
        color={color}
        iconSize={size}
        className={className}
        onClick={onClick}
      />
    );
  }

  throw new Error(`Icon ${name} not found`);
}

export default Icon;
