import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Belt } from "../../layouts/Belt";
import { Spinner } from "@blueprintjs/core";
import useAuth0 from "../../hooks/useAuth0";

function Callback({ history }) {
  const auth0 = useAuth0();

  useEffect(() => {
    if (/access_token|id_token|error/.test(window.location.hash)) {
      auth0.handleAuthentication();
      history.replace("/");
    }
  }, [auth0, history]);

  return (
    <Belt centered width={300}>
      <Spinner />
    </Belt>
  );
}

export default withRouter(Callback);
