import React from "react";
import styles from "./Watch.module.scss";
import VideoDetails from "./VideoDetails";
import BuyButton from "./BuyButton";
import VideoDetailPanel from "./VideoDetailPanel";

function VideoInfo({ video, headerStyle, textStyle, onBuyClick }) {
  const largeScreen = window.innerWidth > 1100;
  const { isNmiAccount } = video.product.owner;
  const securityLogo = isNmiAccount
    ? require("../Checkout/BillingCheckout/nmi_security.svg")
    : require("../Checkout/BillingCheckout/security.svg");
  return (
    <>
      {largeScreen ? (
        <div className={styles.info}>
          <div className={styles.row}>
            <VideoDetails headerStyle={headerStyle} video={video} product={video.product} />
            <div className={styles.checkout}>
              <BuyButton
                price={
                  video.product.isDonation ? (
                    "Donate"
                  ) : (
                    <>
                      <span className={styles.buyNow}>{"Buy Now "}</span>
                      <span>
                        {`${Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD"
                        }).format(video.product.price)}`}
                      </span>
                    </>
                  )
                }
                video={video}
                onClick={onBuyClick}
              />
              <img className={styles.securityLogo} src={securityLogo} alt="" />
            </div>
          </div>
        </div>
      ) : (
        <VideoDetailPanel
          video={video}
          headerStyle={headerStyle}
          textStyle={textStyle}
          onBuyClick={onBuyClick}
        />
      )}
    </>
  );
}

export default VideoInfo;
