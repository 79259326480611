import React from "react";
import classNames from "classnames";
import styles from "./Checkout.module.scss";

export default function CheckoutButton({ children, className, primary = false, ...rest }) {
  return (
    <button
      {...rest}
      className={classNames(className, styles.checkoutButton, { [styles.primary]: primary })}
    >
      {children}
    </button>
  );
}
