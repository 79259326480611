import useInputState from "../../../hooks/useInputState";
import { useMixpanel } from "react-mixpanel-browser";
import CheckoutHelper from "../../../helpers/CheckoutHelper";
import { useContext } from "react";
import VideoContext from "../../../context/VideoContext";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import useMaskedInputState from "../../../hooks/useMaskedInputState";

const getStates = gql`
  query getStates($countryId: String) {
    states(countryId: $countryId) {
      id
      name
    }
  }
`;

const getCountries = gql`
  query getCountries {
    countries {
      id
      name
    }
  }
`;

export function useShippingAddressForm({ data, dispatch, increaseStep, name, video, product }) {
  const [suffix, setSuffix] = useMaskedInputState(data.suffix || "", { maxLength: 255 });
  const [firstName, setFirstName] = useMaskedInputState(data.firstName || "", { maxLength: 255 });
  const [middleInitial, setMiddleInitial] = useMaskedInputState(data.middleInitial || "", {
    maxLength: 255
  });
  const [lastName, setLastName] = useMaskedInputState(data.lastName || "", { maxLength: 255 });
  const [email, setEmail] = useMaskedInputState(data.email || "", { maxLength: 255 });
  const [streetName, setStreetName] = useMaskedInputState(data.streetName || "", {
    maxLength: 255
  });
  const [city, setCity] = useMaskedInputState(data.city || "", { maxLength: 255 });
  const [addressCountry, setAddressCountry] = useInputState(data.addressCountry || "1");
  const [addressState, setAddressState] = useInputState(data.addressState || "1");
  const [postalCode, setPostalCode] = useInputState(data.postalCode || "");
  const [customerNote, setCustomerNote] = useMaskedInputState(data.customerNote || "", {
    maxLength: 1000
  });
  const isEmailValid = email && /\S+@\S+\.\S+/.test(email);
  const mixpanel = useMixpanel();
  const [{ videoLength, watchTime }] = useContext(VideoContext);
  const { data: statesData } = useQuery(getStates, {
    variables: {
      countryId: addressCountry
    },
    skip: !addressCountry
  });
  const { data: countriesData } = useQuery(getCountries);
  const statesList = [
    { label: "State", value: "", disabled: true },
    ...((statesData && statesData.states.map(({ id, name }) => ({ value: id, label: name }))) || [])
  ];
  const countriesList = [
    { label: "Country", value: "", disabled: true },
    ...((countriesData &&
      countriesData.countries.map(({ id, name }) => ({ value: id, label: name }))) ||
      [])
  ];

  const onNext = async () => {
    const data = {
      suffix,
      firstName,
      middleInitial,
      lastName,
      email,
      streetName,
      city,
      addressState,
      addressCountry,
      postalCode,
      customerNote
    };

    dispatch({ type: "fulfilled", name, data });
    CheckoutHelper.trackShippingInfoProvided(
      { video, product, watchTime, videoLength },
      { mixpanel }
    );
    increaseStep();
  };

  return {
    suffix,
    setSuffix,
    firstName,
    setFirstName,
    middleInitial,
    setMiddleInitial,
    lastName,
    setLastName,
    email,
    setEmail,
    isEmailValid,
    streetName,
    setStreetName,
    city,
    setCity,
    addressState,
    setAddressState,
    addressCountry,
    setAddressCountry,
    statesList,
    countriesList,
    postalCode,
    setPostalCode,
    customerNote,
    setCustomerNote,
    onNext,
    isFormValid:
      !!firstName &&
      !!lastName &&
      isEmailValid &&
      !!streetName &&
      !!city &&
      !!addressState &&
      !!postalCode
  };
}
