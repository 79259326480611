import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import useInputState from "../../../hooks/useInputState";

import { Spinner, FormGroup, InputGroup, Button, Intent } from "@blueprintjs/core";
import DefaultToaster from "../../../atoms/DefaultToaster";
import { Belt } from "../../../layouts/Belt";

import { GetShippingInfo } from "./queries";
import { SaveAddress } from "./mutations";

import styles from "../Settings.module.scss";

function AddressSettings() {
  const { data, loading } = useQuery(GetShippingInfo);
  const [saveAddress] = useMutation(SaveAddress, { refetchQueries: [{ query: GetShippingInfo }] });

  const [name, setName] = useInputState("");
  const [street1, setStreet1] = useInputState("");
  const [city, setCity] = useInputState("");
  const [state, setState] = useInputState("AL");
  const [postalCode, setPostalCode] = useInputState("");

  const savedAddress = data?.accounts?.[0]?.shippingMethods?.[0];

  useEffect(() => {
    if (!loading && savedAddress) {
      setName(savedAddress.name);
      setStreet1(savedAddress.street1);
      setCity(savedAddress.city);
      setState(savedAddress.state);
      setPostalCode(savedAddress.postalCode);
    }
  }, [loading, savedAddress]);

  const onSave = async () => {
    try {
      await saveAddress({
        variables: {
          address: {
            name,
            street1,
            city,
            state,
            postalCode,
            type: "Destination"
          }
        },
        refetchQueries: [{ query: GetShippingInfo }]
      });

      DefaultToaster.show({
        intent: Intent.SUCCESS,
        message: "Address successfully saved to your account!!"
      });
    } catch (err) {
      DefaultToaster.show({
        intent: Intent.DANGER,
        message:
          "Something went wrong! Check that your address is valid. Contact support if the problem persists."
      });
    }
  };

  if (loading) {
    return (
      <Belt>
        <Spinner />
      </Belt>
    );
  }

  return (
    <div className={styles.panel}>
      <h4>Address</h4>
      <p>Save your shipping address to simplify your checkout experience in the future.</p>

      <FormGroup label="Name" labelFor="name">
        <InputGroup id="name" value={name} onChange={setName} />
      </FormGroup>

      <FormGroup label="Street Address" labelFor="street-1">
        <InputGroup id="street-1" value={street1} onChange={setStreet1} />
      </FormGroup>

      <div className={styles.inputGroup}>
        <FormGroup label="City" labelFor="city">
          <InputGroup id="city" value={city} onChange={setCity} />
        </FormGroup>

        <FormGroup id="state-form-group" label="State" labelFor="state">
          <InputGroup id="state" value={state} onChange={setState} />
        </FormGroup>

        <FormGroup label="Zip Code" labelFor="postal-code">
          <InputGroup id="postal-code" value={postalCode} onChange={setPostalCode} />
        </FormGroup>
      </div>

      <div className={styles.buttonGroup}>
        <Button
          intent={Intent.PRIMARY}
          onClick={onSave}
          text="Save"
          disabled={!name || !street1 || !city || !state || !postalCode}
        />
      </div>
    </div>
  );
}

export default AddressSettings;
