import React, { useState, useContext, useEffect } from "react";
import { Icon } from "@blueprintjs/core";

import { useMixpanel } from "react-mixpanel-browser";
import { Tabs, Tab, FormGroup, InputGroup, TextArea, RadioGroup, Radio } from "@blueprintjs/core";

import { Facebook, Twitter, Linkedin } from "react-feather";
import styles from "./Share.module.scss";
import VideoContext from "../../context/VideoContext";
import { getDimensions } from "./helper";

function SocialMediaButton({ icon, color, onClick }) {
  return (
    <button
      className={styles.socialMediaButton}
      style={{ backgroundColor: color }}
      onClick={onClick}
    >
      {icon}
    </button>
  );
}

function ShareSheet({ video, closeHandler }) {
  const mixpanel = useMixpanel();
  const [videoState] = useContext(VideoContext);
  const [tabId, setTabId] = useState("link");
  const [embedType, setEmbedType] = useState("Fixed");
  const [height, setHeight] = useState("600");
  const [width, setWidth] = useState("960");
  const [aspectRatio, setAspectRatio] = useState(56.25);

  getDimensions(video.url).then(({ ratio }) => {
    if (ratio < 100) {
      setAspectRatio(ratio);
    } else {
      setAspectRatio(100);
    }
  });

  useEffect(() => {
    isNaN(parseInt(height)) && setHeight("");
    isNaN(parseInt(width)) && setWidth("");
  }, [height, width]);

  const onShareClick = platform => {
    mixpanel.track("Video Shared", {
      VideoId: video.id,
      VideoName: video.name,
      ProductId: video.product.id,
      ProductName: video.product.name,
      ProductSku: video.product.sku,
      WatchTime: videoState.watchTime,
      VideoLength: videoState.videoLength,
      Platform: platform
    });

    shareTo(platform);
  };

  const embedCode =
    `<iframe width="${width || 960}" height="${height || 600}" ` +
    `src="${window.location.origin}/watch/${video.id}" ` +
    `frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>`;

  const responsiveEmbedCode =
    `<div style="padding:${aspectRatio}% 0 0 0;position:relative;min-height:400px;">` +
    `<iframe style="position:absolute;top:0;left:0;width:100%;height:100%;" ` +
    `src="${window.location.origin}/watch/${video.id}" ` +
    `frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>` +
    `</div>`;

  function shareTo(socialNetwork) {
    const videoUrl = `${window.location.origin}/watch/${video.id}`;

    switch (socialNetwork) {
      case "facebook":
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(videoUrl)}`);
        break;

      case "twitter":
        window.open(`http://twitter.com/intent/tweet/?url=${encodeURIComponent(videoUrl)}`);
        break;

      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            videoUrl
          )}&title=${video.name}&summary=${video.description}&source=LinkedIn`
        );
        break;

      default:
        return;
    }
  }

  const linkTab = (
    <>
      <FormGroup label="URL" helperText="Copy and paste this URL to share the video.">
        <InputGroup type="url" value={`${window.location.origin}/watch/${video.id}`} large />
      </FormGroup>
    </>
  );

  const socialTab = (
    <>
      <p>Choose a social network to share to...</p>

      <div className={styles.socialMediaButtons}>
        <SocialMediaButton
          icon={<Facebook color="#FFF" />}
          color="#4267B2"
          onClick={() => onShareClick("facebook")}
        />
        <SocialMediaButton
          icon={<Twitter color="#fff" />}
          color="#1DA1F2"
          onClick={() => onShareClick("twitter")}
        />
        <SocialMediaButton
          icon={<Linkedin color="#fff" />}
          color="#0077B5"
          onClick={() => onShareClick("linkedin")}
        />
      </div>
    </>
  );

  const embedTab = (
    <>
      <FormGroup
        label="Embed Code"
        helperText="Copy and paste this code on your blog or website to embed this video."
      >
        <TextArea
          className={styles.embed}
          value={embedType === "Fixed" ? embedCode : responsiveEmbedCode}
          fill
        />
        <RadioGroup
          inline
          onChange={event => setEmbedType(event.currentTarget.value)}
          selectedValue={embedType}
        >
          <Radio label="Fixed" value="Fixed" />
          <Radio label="Responsive" value="Responsive" />
        </RadioGroup>
        {embedType === "Fixed" && (
          <div className={styles.fixedVideoOptions}>
            <InputGroup
              className={styles.optionInput}
              value={width}
              onChange={({ target: { value } }) => setWidth(parseInt(value))}
              placeholder="960"
            ></InputGroup>
            <span>x</span>
            <InputGroup
              className={styles.optionInput}
              value={height}
              onChange={({ target: { value } }) => setHeight(parseInt(value))}
              placeholder="600"
            ></InputGroup>
          </div>
        )}
      </FormGroup>
    </>
  );

  return (
    <div className={styles.shareSheet}>
      <h5>Share "{video.name}"</h5>
      <Icon icon="cross" iconSize={20} onClick={closeHandler} className={styles.closeButton} />
      <Tabs selectedTabId={tabId} onChange={setTabId}>
        <Tab id="link" title="Link" panel={linkTab} />
        <Tab id="social" title="Social" panel={socialTab} />
        <Tab id="embed" title="Embed" panel={embedTab} />
      </Tabs>
    </div>
  );
}

export default ShareSheet;
