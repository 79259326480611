import React from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import useInputState from "../../../hooks/useInputState";

import Cleave from "cleave.js/react";
import { InputGroup, FormGroup, Button, Intent, Spinner } from "@blueprintjs/core";
import { Belt } from "../../../layouts/Belt";
import DefaultToaster from "../../../atoms/DefaultToaster";

import { GetPaymentInfo } from "./queries";
import { SavePaymentMethod } from "./mutations";

import CheckoutHelper from "../../../helpers/CheckoutHelper";

import styles from "../Settings.module.scss";

function EditPaymentSettings() {
  const history = useHistory();
  const { data, loading } = useQuery(GetPaymentInfo);
  const [saveCard] = useMutation(SavePaymentMethod, {
    refetchQueries: [{ query: GetPaymentInfo }],
    awaitRefetchQueries: true
  });

  const [cardNumber, setCardNumber] = useInputState("");
  const [name, setName] = useInputState("");
  const [expiration, setExpiration] = useInputState("");
  const [ccv, setCCV] = useInputState("");
  const [postalCode, setPostalCode] = useInputState("");

  const paymentMethod = data?.accounts?.[0]?.paymentMethods?.[0];

  const onSave = async () => {
    try {
      const { id: tokenId } = await CheckoutHelper.createTokenFromCard({
        cardholderName: name,
        cardNumber,
        expiration,
        ccv,
        zip: postalCode
      });

      await saveCard({
        variables: {
          tokenId
        }
      });

      DefaultToaster.show({
        intent: Intent.SUCCESS,
        message: "Credit card successfully saved to your account!"
      });

      history.push("/settings/credit-card");
    } catch (err) {
      DefaultToaster.show({ message: err.message, intent: Intent.DANGER });
    }
  };

  if (loading) {
    return (
      <Belt>
        <Spinner />
      </Belt>
    );
  }

  return (
    <div className={styles.panel}>
      <h4>Credit Card</h4>
      <p>Save your credit card to simplify your checkout experience in the future.</p>

      <FormGroup label="Card Number" labelFor="card-number">
        <Cleave
          id="card-number"
          className="bp3-input bp3-fill"
          options={{ creditCard: true }}
          value={cardNumber}
          onChange={setCardNumber}
        />
      </FormGroup>

      <FormGroup label="Name on Card" labelFor="name">
        <InputGroup id="name" value={name} onChange={setName} autoCapitalize="words" />
      </FormGroup>

      <div className={styles.inputGroup}>
        <FormGroup label="Expiration Date" labelFor="expiration">
          <Cleave
            id="expiration"
            className="bp3-input bp3-fill"
            value={expiration}
            onChange={setExpiration}
            options={{ date: true, datePattern: ["m", "y"] }}
          />
        </FormGroup>

        <FormGroup label="CCV" labelFor="ccv">
          <Cleave
            id="ccv"
            className="bp3-input bp3-fill"
            value={ccv}
            onChange={setCCV}
            options={{ numericOnly: true }}
          />
        </FormGroup>

        <FormGroup label="Zip Code" labelFor="postal-code">
          <Cleave
            id="postal-code"
            className="bp3-input bp3-fill"
            value={postalCode}
            onChange={setPostalCode}
            options={{ numericOnly: true }}
          />
        </FormGroup>
      </div>

      <div className={styles.buttonGroup}>
        <Button intent={Intent.PRIMARY} onClick={onSave} text="Save" />

        {paymentMethod && (
          <Button minimal onClick={() => history.push("/settings/credit-card")} text="Cancel" />
        )}
      </div>
    </div>
  );
}

export default EditPaymentSettings;
