import React from "react";
import styles from "./Watch.module.scss";
import moment from "moment";
import { Tooltip } from "@blueprintjs/core";

function BuyButton({ price, onClick, video }) {
  const noStock =
    video.product.variants.length > 0
      ? video.product.variants.every(variant => variant.stock === 0)
      : video.product.stock === 0;

  const expiredOpportunity =
    !!video.opportunity &&
    (video.opportunity.isDiscontinued || moment(video.opportunity.expiresAt).isBefore(moment()));

  const influencerApplicationEvents =
    (!!video.opportunity &&
      video.opportunity.applicationEvents.filter(event => event.applicant.id === video.owner.id)) ||
    [];

  const influencerRemoved =
    influencerApplicationEvents.length > 0 &&
    influencerApplicationEvents[influencerApplicationEvents.length - 1].status !== "Approved";

  const validSubscription = video.product.owner.isActive;

  return (
    <Tooltip
      boundary="window"
      content={
        !validSubscription
          ? "This product is temporarily disabled"
          : noStock
          ? "This product is out of stock"
          : "This product is no longer available through this video"
      }
      disabled={!(noStock || expiredOpportunity || influencerRemoved || !validSubscription)}
    >
      <div
        className={styles.buy}
        disabled={noStock || expiredOpportunity || influencerRemoved || !validSubscription}
        onClick={onClick}
      >
        {price}
      </div>
    </Tooltip>
  );
}

export default BuyButton;
