import React from "react";
import styles from "./Player.module.scss";

function Video({ url, muted, thumbnailUrl }, ref) {
  return (
    <video
      className={styles.video}
      autoPlay={false}
      muted={muted}
      ref={ref}
      poster={thumbnailUrl}
      playsInline
      loop
    >
      <source src={url} type="video/mp4" />
    </video>
  );
}

export default React.forwardRef(Video);
