import React from "react";
import { Popover, Position, Classes, Icon, Button } from "@blueprintjs/core";
import styles from "./BillingCheckout.module.scss";

export default function SecureInfoPopover({ isNmiAccount }) {
  const logo = !isNmiAccount ? require("./powered_by_stripe.svg") : require("./nmi_logo.svg");
  return (
    <Popover
      minimal
      position={Position.BOTTOM_RIGHT}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
    >
      <Button minimal small icon="lock" tabIndex={-1} />
      <div className={styles.cvvInfo}>
        <Icon icon="lock" iconSize={15} />
        <div>
          <p>
            Your credit card info is sent securely to Stripe using SSL. We do not store your credit
            card information on our servers.
          </p>
          <img
            src={logo}
            alt={`Powered by ${isNmiAccount ? "NMI" : "Stripe"}`}
            style={{ height: 22 }}
          />
        </div>
      </div>
    </Popover>
  );
}
