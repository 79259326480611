import gql from "graphql-tag";

export const GetPaymentInfo = gql`
  query GetPaymentInfo {
    accounts {
      id

      paymentMethods {
        cardId
        type
        last4
      }

      shippingMethods {
        id
        street1
        street2
        city
        state
        country
        postalCode
        name
        isDefault
      }
    }
  }
`;
