import React from "react";
import styles from "./AccountForm.module.scss";
import { Button, Spinner, Intent } from "@blueprintjs/core";
import Icon from "../../atoms/Icon/Icon";

function AccountForm({
  onSubmit,
  headerText,
  children,
  buttonText,
  hideButton,
  disabled,
  topLinkComponent,
  bottomLinkComponent,
  loading,
  backButton,
  onBackPress
}) {
  return (
    <form onSubmit={onSubmit}>
      <h1 className={styles.header}>{headerText}</h1>

      <div className={styles.groups}>{children}</div>

      {topLinkComponent}

      {backButton && (
        <Button className={styles.backButton} onClick={onBackPress}>
          <Icon size={24} className={styles.icon} name="arrow-left" />
        </Button>
      )}

      {!hideButton && (
        <Button type="submit" className={styles.button} disabled={disabled || loading}>
          {loading ? (
            <div style={{ width: 96 }}>
              <Spinner intent={Intent.WARNING} size={26} />
            </div>
          ) : (
            <div className={styles.buttonTextWrapper}>{buttonText}</div>
          )}
          {!loading && (
            <div className={styles.iconWrapper}>
              <Icon size={24} className={styles.icon} name="arrow-right" />
            </div>
          )}
        </Button>
      )}

      {bottomLinkComponent}
    </form>
  );
}

export default AccountForm;
