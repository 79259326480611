import React from "react";
import { InputGroup, FormGroup, HTMLSelect as Select, TextArea, Intent } from "@blueprintjs/core";
import {
  useCheckoutReducer,
  useCheckoutState,
  SHIPPING_PAGE
} from "../../../molecules/CheckoutDrawer/CheckoutDrawer";
import styles from "./ShippingAddressCheckout.module.scss";
import { useShippingAddressForm } from "./hooks";
import CheckoutStep from "../../../molecules/Checkout/CheckoutStep";
import CheckoutContent from "../../../molecules/Checkout/CheckoutContent";
import CheckoutButton from "../../../molecules/Checkout/CheckoutButton";
import CheckoutActions from "../../../molecules/Checkout/CheckoutActions";

function ShippingAddressCheckout({ increaseStep, decreaseStep, setStep }) {
  const state = useCheckoutState();
  const dispatch = useCheckoutReducer();
  const { data } = state.requirements[SHIPPING_PAGE];
  const { video, product } = state;
  const {
    suffix,
    setSuffix,
    firstName,
    setFirstName,
    middleInitial,
    setMiddleInitial,
    lastName,
    setLastName,
    email,
    setEmail,
    streetName,
    setStreetName,
    city,
    setCity,
    addressState,
    setAddressState,
    addressCountry,
    setAddressCountry,
    countriesList,
    statesList,
    postalCode,
    setPostalCode,
    customerNote,
    setCustomerNote,
    onNext,
    isFormValid
  } = useShippingAddressForm({
    data: data || {},
    dispatch,
    increaseStep,
    name: SHIPPING_PAGE,
    video,
    product
  });

  return (
    <CheckoutStep>
      <CheckoutContent>
        <div className={styles.formRow}>
          <FormGroup label="First Name">
            <InputGroup
              placeholder="First Name"
              value={firstName}
              onChange={setFirstName}
              autoFocus
              required
              large
            />
          </FormGroup>
          <FormGroup label="Middle Initial">
            <InputGroup
              placeholder="Middle Initial"
              value={middleInitial}
              onChange={setMiddleInitial}
              required
              large
            />
          </FormGroup>
        </div>

        <div className={styles.formRow}>
          <FormGroup label="Last Name">
            <InputGroup
              placeholder="Last Name"
              value={lastName}
              onChange={setLastName}
              required
              large
            />
          </FormGroup>
          <FormGroup label="Suffix">
            <InputGroup placeholder="Suffix" value={suffix} onChange={setSuffix} required large />
          </FormGroup>
        </div>

        <FormGroup label="Email">
          <InputGroup
            placeholder="Email"
            type="email"
            value={email}
            onChange={setEmail}
            required
            large
          />
        </FormGroup>

        <FormGroup label="Street Name">
          <InputGroup
            placeholder="Street Name"
            value={streetName}
            onChange={setStreetName}
            required
            large
          />
        </FormGroup>

        <div className={styles.formRow}>
          <FormGroup label="Country" className={styles.selectDropdown}>
            <Select options={countriesList} value={addressCountry} onChange={setAddressCountry} />
          </FormGroup>

          <FormGroup label="State" className={styles.selectDropdown}>
            <Select
              disabled={!addressCountry}
              options={statesList}
              value={addressState}
              onChange={setAddressState}
            />
          </FormGroup>
        </div>

        <div className={styles.formRow}>
          <FormGroup label="City">
            <InputGroup placeholder="City" value={city} onChange={setCity} required large />
          </FormGroup>

          <FormGroup label="Zip Code" className={styles.postalCode}>
            <InputGroup
              placeholder="Zip Code"
              value={postalCode}
              onChange={setPostalCode}
              required
              large
            />
          </FormGroup>
        </div>
        <FormGroup label="Leave a Note">
          <TextArea
            growVertically={true}
            large={true}
            style={{ height: "140px", width: "100%" }}
            intent={Intent.PRIMARY}
            value={customerNote}
            onChange={setCustomerNote}
            placeholder="Enter note here"
          />
        </FormGroup>
      </CheckoutContent>

      <CheckoutActions>
        <CheckoutButton disabled={!isFormValid} onClick={onNext}>
          Next
        </CheckoutButton>
      </CheckoutActions>
    </CheckoutStep>
  );
}

export default ShippingAddressCheckout;
