import React, { useState } from "react";
import { Button, Popover, Position, Classes } from "@blueprintjs/core";
import VideoInfo from "../../VideoInfo/VideoInfo";
import styles from "../Player.module.scss";
import Share from "../../Share/Share";
import classNames from "classnames";

function VideoActions({ video, onBuyClick }) {
  const isLargeScreen = window.innerWidth > 1100;
  const [dialog, setDialog] = useState(null);

  return (
    <div className={styles.actions}>
      <VideoInfo
        video={video}
        onBuyClick={onBuyClick}
        componentName={dialog}
        onDrawerClose={() => setDialog(null)}
        isLargeScreen={isLargeScreen}
        setDialog={setDialog}
      />
      <Share
        video={video}
        componentName={dialog}
        onDrawerClose={() => setDialog(null)}
        isLargeScreen={isLargeScreen}
        setDialog={setDialog}
      />
      {!isLargeScreen && (
        <Popover
          style={{ height: 200, overflow: "auto" }}
          position={Position.BOTTOM_RIGHT}
          minimal
          content={
            <div className={styles.actionBtns}>
              <Button
                onClick={() => setDialog("VideoInfo")}
                className={classNames(Classes.POPOVER_DISMISS)}
                minimal
                icon="info-sign"
              >
                Info
              </Button>
              <Button
                onClick={() => setDialog("Share")}
                className={classNames(Classes.POPOVER_DISMISS)}
                minimal
                icon="share"
              >
                Share
              </Button>
            </div>
          }
          target={<Button className={styles.actionTarget} minimal icon="more" />}
        />
      )}
    </div>
  );
}

export default VideoActions;
