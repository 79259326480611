import React from "react";
import styles from "./Error.module.scss";

export default function NotFound() {
  return (
    <div className={styles.errorPage}>
      <div className={styles.errorIcon}>
        <img src={require("../../assets/404.svg")} alt="" />
      </div>
      <div className={styles.errorMessage}>
        <h2 className={styles.heading}>Page Not Found</h2>
        <div className={styles.description}>
          <p>
            The page you requested doesn't exist or has been removed. You may want to double check
            the link with the person or organization who sent it to you.
          </p>
        </div>
      </div>
    </div>
  );
}
