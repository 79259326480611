export const getDimensions = videoUrl => {
  let video = document.createElement("video");
  video.src = videoUrl;
  return new Promise(resolve => {
    video.addEventListener("loadedmetadata", () => {
      const height = video.videoHeight;
      const width = video.videoWidth;
      const ratio = (video.videoHeight / video.videoWidth) * 100;
      resolve({
        height,
        width,
        ratio: parseFloat(ratio).toFixed(2)
      });
    });
  });
};
