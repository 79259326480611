import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { useMixpanel } from "react-mixpanel-browser";
import useAuthState from "../../hooks/useAuthState";

import { TagAccountType } from "./mutations";

import Auth0 from "../../helpers/Auth0";
import LinkHelper from "../../helpers/LinkHelper";
import { isEmail } from "validator";

const useProfileInfo = () => {
  const [name, setName] = useState(null);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mutationLoading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { login } = useAuthState();
  const history = useHistory();
  const mixpanel = useMixpanel();
  const linkHelper = new LinkHelper();

  const [tagAccountType] = useMutation(TagAccountType);

  const disabled =
    mutationLoading ||
    name === "" ||
    username === "" ||
    email === "" ||
    !isEmail(email) ||
    password === "" ||
    password.length < 8;

  const createAccount = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      const auth0 = new Auth0();
      await auth0.createAccount({ name, username, email, password });
      mixpanel.track("Registered");
      await login(email, password, true);
      await tagAccountType({ variables: { type: "Customer" } });
      history.push(linkHelper.redirectParam || "/");
    } catch (err) {
      setLoading(false);
      if (err.code === "user_exists") {
        setError({
          title: "A user with that email address or username already exists.",
          message: "Please try signing in or using the forgot password link."
        });
      } else {
        setError({
          title: "We couldn't create your account at this time.",
          message: "We apologize for the inconvenience. Please try again later."
        });

        throw err;
      }
    }
  };

  return {
    name,
    username,
    email,
    password,
    error,
    disabled,
    mutationLoading,
    setName,
    setUsername,
    setEmail,
    setPassword,
    createAccount
  };
};

export default useProfileInfo;
