import React from "react";
import circle from "../../assets/signIn.svg";
import { LeftExplanation } from "../../molecules/LeftExplanation";

function LoginExplanation() {
  const explanation =
    "We all forget our passwords. Just enter in your account’s email address " +
    "and we’ll have you back up and running in no time.";
  return <LeftExplanation icon={circle} title="It Happens Sometimes" explanation={explanation} />;
}

export default LoginExplanation;
