import { loadStripe } from "@stripe/stripe-js";

export class OutOfStockError extends Error {
  constructor(message) {
    super(message);
    this.name = "OutOfStockError";
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}
export class InvalidCardError extends Error {
  constructor(message) {
    super(message);
    this.name = "InvalidCardError";
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}
export class InvalidCardNumberError extends InvalidCardError {
  constructor(message) {
    super(message);
    this.name = "InvalidCardNumberError";
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}
export class InvalidCardExpirationError extends InvalidCardError {
  constructor(message) {
    super(message);
    this.name = "InvalidCardExpirationError";
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}
export class InvalidCardCvvError extends InvalidCardError {
  constructor(message) {
    super(message);
    this.name = "InvalidCardCvvError";
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}
export class InvalidCardholderNameError extends InvalidCardError {
  constructor(message) {
    super(message);
    this.name = "InvalidCardholderNameError";
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

class CheckoutHelper {
  static async createOrder(
    {
      product,
      video,
      address,
      employerAddress,
      retired,
      email,
      token,
      occupation,
      employer,
      quantity,
      customerNote,
      donationAmount,
      watchTime,
      videoLength,
      paymentRequestButton
    },
    { createOrder, completeOrder, mixpanel }
  ) {
    mixpanel.track("Purchase Attempted", {
      VideoId: video.id,
      VideoName: video.name,
      ProductId: product.id,
      ProductName: product.name,
      ProductSku: product.sku,
      Price: product.price,
      DonationAmount: donationAmount,
      OpportunityId: video.opportunity && video.opportunity.id,
      InfluencerId: video.owner && video.owner.id,
      InfluencerName: video.owner && video.owner.username,
      ProductOwnerId: product.owner && product.owner.id,
      ProductOwnerName: product.owner && product.owner.username,
      Categories: product.categories,
      Quantity: quantity,
      WatchTime: watchTime,
      VideoLength: videoLength
    });

    try {
      const {
        data: { createOrder: createResult }
      } = await createOrder({
        variables: {
          address: address && {
            suffix: address.suffix,
            firstName: address.firstName,
            middleInitial: address.middleInitial,
            lastName: address.lastName,
            street1: address.street1,
            street2: address.street2,
            city: address.city,
            stateId: address.stateId,
            postalCode: address.postalCode,
            country: "United States",
            type: "Destination"
          },
          order: {
            email,
            productId: product.id,
            videoId: video.id,
            tokenId: token,
            employer,
            occupation,
            quantity,
            customerNote,
            donationAmount
          },
          ...(retired
            ? {}
            : {
                employerAddress: employerAddress && {
                  street1: employerAddress.streetName,
                  postalCode: employerAddress.postalCode,
                  city: employerAddress.city,
                  stateId: employerAddress.addressState,
                  country: "United States",
                  type: "Employer"
                }
              })
        }
      });

      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

      const confirmResult = await stripe.confirmCardPayment(
        createResult.stripePaymentIntentSecret,
        {
          payment_method: paymentRequestButton
            ? token
            : {
                type: "card",
                card: { token }
              }
        }
      );

      if (confirmResult.paymentIntent && confirmResult.paymentIntent.status === "succeeded") {
        await completeOrder({
          variables: {
            orderId: createResult.order.id
          }
        });
      }

      mixpanel.track("Purchase Succeeded", {
        OrderId: createResult.order.id,
        VideoId: video.id,
        VideoName: video.name,
        ProductId: product.id,
        ProductName: product.name,
        ProductSku: product.sku,
        Price: product.price,
        OpportunityId: video.opportunity && video.opportunity.id,
        InfluencerId: video.owner && video.owner.id,
        InfluencerName: video.owner && video.owner.username,
        ProductOwnerId: product.owner && product.owner.id,
        ProductOwnerName: product.owner && product.owner.username,
        Categories: product.categories,
        Quantity: quantity,
        WatchTime: watchTime,
        VideoLength: videoLength
      });

      mixpanel.people.track_charge(product.price);

      return createResult.order;
    } catch (e) {
      mixpanel.track("Purchase Failed", {
        VideoId: video.id,
        VideoName: video.name,
        ProductId: product.id,
        ProductName: product.name,
        ProductSku: product.sku,
        Price: product.price,
        OpportunityId: video.opportunity && video.opportunity.id,
        InfluencerId: video.owner && video.owner.id,
        InfluencerName: video.owner && video.owner.username,
        ProductOwnerId: product.owner && product.owner.id,
        ProductOwnerName: product.owner && product.owner.username,
        Categories: product.categories,
        Quantity: quantity,
        WatchTime: watchTime,
        VideoLength: videoLength,
        Reason: e.message
      });

      if (e.message.includes("out_of_stock")) {
        throw new OutOfStockError(`${product.name} is out of stock.`);
      } else {
        throw e;
      }
    }
  }

  static createTokenFromCard({ cardholderName, cardNumber, expiration, cvv, zip }) {
    return new Promise((resolve, reject) => {
      window.Stripe.card.createToken(
        {
          name: cardholderName,
          number: cardNumber,
          cvc: cvv,
          exp_month: expiration.split("/")[0],
          exp_year: expiration.split("/")[1],
          address_zip: zip
        },
        (status, response) => {
          if (status === 200) {
            return resolve(response);
          }

          switch (response.error.param) {
            case "number":
              return reject(new InvalidCardNumberError("Invalid card number."));
            case "exp_year":
              return reject(
                new InvalidCardExpirationError("Invalid card expiration, or card is expired.")
              );
            case "cvc":
              return reject(new InvalidCardCvvError("Invalid CVV."));
            case "name":
              return reject(
                new InvalidCardholderNameError("Invalid or unrecognized cardholder name.")
              );
            default:
              return reject(new InvalidCardError("Invalid card information."));
          }
        }
      );
    });
  }

  static standardizeAddress({
    shippingFirstName,
    shippingLastName,
    shippingAddress,
    shippingCity,
    shippingState,
    shippingPostalCode
  }) {
    return {
      name: [shippingFirstName, shippingLastName].join(" "),
      street1: shippingAddress,
      street2: "",
      city: shippingCity,
      state: shippingState,
      postalCode: shippingPostalCode
    };
  }

  static parseNetworkError(err) {
    console.log(err);

    return err.networkError
      ? err.networkError.result.errors[0].message
      : "Sorry! Something went wrong while placing your order. Please try again later.";
  }

  static trackPurchaseStarted({ video, product, watchTime, videoLength }, { mixpanel }) {
    mixpanel.track("Purchase Started", {
      VideoId: video.id,
      VideoName: video.name,
      ProductId: product.id,
      ProductName: product.name,
      ProductSku: product.sku,
      WatchTime: watchTime,
      VideoLength: videoLength
    });
  }

  static trackQuickPay({ video, product, watchTime, videoLength }, { mixpanel }) {
    mixpanel.track("Quick Pay", {
      VideoId: video.id,
      VideoName: video.name,
      ProductId: product.id,
      ProductName: product.name,
      ProductSku: product.sku,
      WatchTime: watchTime,
      VideoLength: videoLength
    });
  }

  static trackBillingInfoProvided({ video, product, watchTime, videoLength }, { mixpanel }) {
    mixpanel.track("Billing Info Collected", {
      VideoId: video.id,
      VideoName: video.name,
      ProductId: product.id,
      ProductName: product.name,
      ProductSku: product.sku,
      WatchTime: watchTime,
      VideoLength: videoLength
    });
  }

  static trackShippingInfoProvided({ video, product, watchTime, videoLength }, { mixpanel }) {
    mixpanel.track("Shipping Info Collected", {
      VideoId: video.id,
      VideoName: video.name,
      ProductId: product.id,
      ProductName: product.name,
      ProductSku: product.sku,
      WatchTime: watchTime,
      VideoLength: videoLength
    });
  }

  static trackPoliticalInfoProvided({ video, product, watchTime, videoLength }, { mixpanel }) {
    mixpanel.track("Political Info Collected", {
      VideoId: video.id,
      VideoName: video.name,
      ProductId: product.id,
      ProductName: product.name,
      ProductSku: product.sku,
      WatchTime: watchTime,
      VideoLength: videoLength
    });
  }

  static trackVariantInfoProvided(
    { video, product, watchTime, videoLength, variant },
    { mixpanel }
  ) {
    mixpanel.track("Variant Info Collected", {
      VideoId: video.id,
      VideoName: video.name,
      ProductId: product.id,
      ProductName: product.name,
      ProductSku: product.sku,
      VariantId: variant.id,
      VariantSku: variant.sku,
      VariantValues: variant.selectedValues.map(v => v.name).join(","),
      WatchTime: watchTime,
      VideoLength: videoLength
    });
  }

  static trackDonationInfoProvided(
    { video, product, watchTime, videoLength, donationAmount },
    { mixpanel }
  ) {
    mixpanel.track("Donation Info Collected", {
      VideoId: video.id,
      VideoName: video.name,
      ProductId: product.id,
      ProductName: product.name,
      ProductSku: product.sku,
      DonationAmount: donationAmount,
      WatchTime: watchTime,
      VideoLength: videoLength
    });
  }

  static buildVideoProperties({
    video,
    videoState = { watchTime: "0", videoLength: "0" },
    ...props
  }) {
    const { id, name, product, owner } = video;

    return {
      VideoId: id,
      VideoName: name,
      InfluencerId: owner.id,
      InfluencerName: owner.name,
      ProductId: product && product.id,
      ProductName: product && product.name,
      OwnerId: product && product.owner && product.owner.id,
      OwnerName: product && product.owner && product.owner.name,
      WatchTime: videoState.watchTime,
      VideoLength: videoState.videoLength,
      ...props
    };
  }

  static beginApplePaySession(
    { owner, amount, video, product, employer, occupation, watchTime, videoLength },
    { createOrder, mixpanel }
  ) {
    mixpanel.track("Apple Pay Attempted", {
      VideoId: video.id,
      VideoName: video.name,
      ProductId: product.id,
      ProductName: product.name,
      ProductSku: product.sku,
      WatchTime: watchTime,
      VideoLength: videoLength
    });

    const session = window.Stripe.applePay.buildSession(
      {
        countryCode: "US",
        currencyCode: "USD",
        total: {
          label: owner,
          amount
        },
        requiredBillingContactFields: ["name", "email"],
        requiredShippingContactFields: ["name", "phone", "email", "postalAddress"]
      },
      async (result, completion) => {
        try {
          const { shippingContact, token } = result;

          await this.createOrder(
            {
              video,
              product,
              token: token.id,
              email: shippingContact.email,
              employer,
              occupation,
              address: {
                street1: shippingContact.addressLines[0],
                street2: shippingContact.addressLines[1],
                city: shippingContact.locality,
                state: shippingContact.administrativeArea,
                postalCode: shippingContact.postalCode,
                name: [shippingContact.givenName, shippingContact.familyName].join(" ")
              },
              watchTime,
              videoLength
            },
            {
              mixpanel,
              createOrder
            }
          );

          completion(window.ApplePaySession.STATUS_SUCCESS);
        } catch (e) {
          mixpanel.track("Apple Pay Failed", {
            VideoId: video.id,
            VideoName: video.name,
            ProductId: product.id,
            ProductName: product.name,
            ProductSku: product.sku,
            WatchTime: watchTime,
            VideoLength: videoLength,
            Reason: e.message
          });

          completion(window.ApplePaySession.STATUS_FAILURE);
        }
      }
    );

    session.oncancel = () => {
      mixpanel.track("Apple Pay Cancelled", {
        VideoId: video.id,
        VideoName: video.name,
        ProductId: product.id,
        ProductName: product.name,
        ProductSku: product.sku,
        WatchTime: watchTime,
        VideoLength: videoLength
      });
    };

    session.begin();
  }
}

export default CheckoutHelper;
