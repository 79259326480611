import React from "react";
import Helmet from "react-helmet";
import classNames from "classnames";

function Page({ title, children, style, className }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className={classNames("page", className)} style={style}>
        {children}
      </main>
    </>
  );
}

export default Page;
