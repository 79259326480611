import * as React from "react";
import classNames from "classnames";
import styles from "./Receipt.module.scss";

export default function ReceiptWrapper({ children, noPadding = false }) {
  return (
    <main className={styles.receiptWrapper}>
      <div className={classNames(styles.receiptContainer, { [styles.noPadding]: noPadding })}>
        {children}
      </div>
    </main>
  );
}
