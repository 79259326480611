import React from "react";
import { FocusStyleManager } from "@blueprintjs/core";
import { BrowserRouter as Router } from "react-router-dom";
import { MixpanelProvider } from "react-mixpanel-browser";

import Routes from "./Routes";
import Auth from "./Auth";
import Apollo from "./Apollo";

import "normalize.css/normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "../../styles/BlueprintOverrides/index.scss";
import "./App.css";

FocusStyleManager.onlyShowFocusOnTabs();

function App() {
  return (
    <Router>
      <MixpanelProvider token={process.env.REACT_APP_MIXPANEL_TOKEN}>
        <Auth>
          <Apollo>
            <Routes />
          </Apollo>
        </Auth>
      </MixpanelProvider>
    </Router>
  );
}

export default App;
