import React from "react";
import styles from "./VariantCheckout.module.scss";
import { filterImpossibleValues, isValueDisabled } from "./helpers";
import classNames from "classnames";

function VariantOption({ option, value, variants, selections, onClick }) {
  return (
    <button
      className={classNames({
        [styles.selected]: selections[option.id] === value.id
      })}
      disabled={isValueDisabled(variants, selections, option.id, value.id)}
      key={value.id}
      onClick={onClick}
    >
      {value.name}
    </button>
  );
}

function VariantOptionSet({ option, variants, selections, onSelect }) {
  return (
    <div className={styles.variantOptionSet}>
      <label>{option.name}</label>
      <div className={styles.variantValues}>
        {filterImpossibleValues(variants, option.values).map(value => (
          <VariantOption
            key={value.id}
            option={option}
            value={value}
            variants={variants}
            selections={selections}
            onClick={ev =>
              onSelect({
                event: ev,
                optionId: option.id,
                valueId: value.id
              })
            }
          />
        ))}
      </div>
    </div>
  );
}

export default function VariantOptionList({ variants, selections, options, onSelect }) {
  return (
    variants.length > 0 && (
      <div className={styles.variantOptionList}>
        {options.map(option => (
          <VariantOptionSet
            key={option.id}
            option={option}
            variants={variants}
            selections={selections}
            onSelect={onSelect}
          />
        ))}
      </div>
    )
  );
}
