import React from "react";
import useAuthState from "../../hooks/useAuthState";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

function Apollo({ children }) {
  const { isLoggedIn, token } = useAuthState();

  const client = new ApolloClient({
    link: new HttpLink({
      uri: process.env.REACT_APP_API_URL,
      headers: {
        Authorization: isLoggedIn ? `Bearer ${token}` : null
      }
    }),
    cache: new InMemoryCache()
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default Apollo;
