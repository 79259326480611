import React, { useState, useContext } from "react";
import {
  useCheckoutState,
  useCheckoutReducer,
  VARIANT_PAGE
} from "../../../molecules/CheckoutDrawer/CheckoutDrawer";
import { useMixpanel } from "react-mixpanel-browser";
import { consolidateVariants, onSelectValueHof } from "./helpers";
import CheckoutHelper from "../../../helpers/CheckoutHelper";
import VideoContext from "../../../context/VideoContext";

import { Spinner } from "@blueprintjs/core";
import CheckoutStep from "../../../molecules/Checkout/CheckoutStep";
import CheckoutContent from "../../../molecules/Checkout/CheckoutContent";
import CheckoutInfo from "../../../molecules/Checkout/CheckoutInfo";
import CheckoutActions from "../../../molecules/Checkout/CheckoutActions";
import CheckoutButton from "../../../molecules/Checkout/CheckoutButton";
import VariantOptionList from "./VariantOptionList";
import SelectedVariant from "./SelectedVariant";

import styles from "./VariantCheckout.module.scss";

function VariantCheckout({ step, increaseStep, decreaseStep }) {
  const mixpanel = useMixpanel();
  const [{ videoLength, watchTime }] = useContext(VideoContext);
  const state = useCheckoutState();
  const { video, product } = state;
  const dispatch = useCheckoutReducer();
  const [selections, setSelections] = useState(
    state.requirements[VARIANT_PAGE].data ? state.requirements[VARIANT_PAGE].data.selections : {}
  );
  const [variant, setVariant] = useState(
    state.requirements[VARIANT_PAGE].data ? state.requirements[VARIANT_PAGE].data.variant : null
  );

  const variants = state.product.variants;

  if (!variants) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner></Spinner>
      </div>
    );
  }

  const consolidatedVariants = consolidateVariants(variants);

  const onSelectValue = onSelectValueHof({
    product: state.product,
    variants,
    selections,
    consolidatedVariants,
    setSelections,
    setVariant
  });

  const onNextClick = () => {
    dispatch({ type: "fulfilled", name: VARIANT_PAGE, data: { variant, selections } });
    CheckoutHelper.trackVariantInfoProvided(
      { video, product, watchTime, videoLength, variant },
      { mixpanel }
    );
    increaseStep();
  };

  return (
    <CheckoutStep className={styles.variantCheckout}>
      <CheckoutInfo>
        <p>Select your desired product options.</p>
      </CheckoutInfo>

      <CheckoutContent>
        <VariantOptionList
          options={state.product.options}
          variants={consolidatedVariants}
          selections={selections}
          onSelect={onSelectValue}
        />
      </CheckoutContent>

      {variant && <SelectedVariant variant={variant} />}

      <CheckoutActions>
        <CheckoutButton disabled={!variant} onClick={onNextClick}>
          Next
        </CheckoutButton>
      </CheckoutActions>
    </CheckoutStep>
  );
}

export default VariantCheckout;
