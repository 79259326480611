import React from "react";
import CheckoutStep from "./CheckoutStep";
import styles from "./CheckoutStepHeader.module.scss";
import {
  BILLING_PAGE,
  VARIANT_PAGE,
  POLITICAL_PAGE,
  SHIPPING_PAGE,
  REVIEW_PAGE,
  DONATION_PAGE
} from "../../molecules/CheckoutDrawer/CheckoutDrawer";

function getName(name) {
  switch (name) {
    case BILLING_PAGE:
      return "Payment Information";
    case VARIANT_PAGE:
      return "Select Options";
    case POLITICAL_PAGE:
      return "Disclaimer";
    case SHIPPING_PAGE:
      return "Contact & Shipping";
    case REVIEW_PAGE:
      return "Review Purchase";
    case DONATION_PAGE:
      return "Donation";
    default:
      throw new Error("Unknown Requirement Name");
  }
}

function CheckoutStepHeader({ currentStep, requirements, setStep }) {
  const checkoutSteps = requirements.map((requirement, index) => {
    const step = index + 1;
    const name = getName(requirement.name);
    const isLastStep = requirement.name === REVIEW_PAGE;
    return (
      <CheckoutStep
        key={step}
        step={step}
        isCurrentStep={step === currentStep + 1}
        isRightStep={step > currentStep + 1}
        setStep={setStep}
        name={name}
        isLastStep={isLastStep}
      />
    );
  });

  return <div className={styles.wrapper}>{checkoutSteps}</div>;
}

export default CheckoutStepHeader;
