import gql from "graphql-tag";

export const DeletePaymentMethod = gql`
  mutation DeletePaymentMethod($cardId: String!) {
    removePaymentMethod(cardId: $cardId)
  }
`;

export const SavePaymentMethod = gql`
  mutation SavePaymentMethod($tokenId: String!) {
    addPaymentMethod(tokenId: $tokenId) {
      cardId
    }
  }
`;
