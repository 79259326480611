export function formatDonation(amount) {
  return parseFloat(amount.toFixed(2));
}

export function getMinimumDonation(variableFee, fixedFee, isPercentage = 0, amount = 0) {
  if (isPercentage) {
    return formatDonation(fixedFee / (1 - variableFee - amount / 100));
  } else {
    return formatDonation((fixedFee + amount) / (1 - variableFee));
  }
}
