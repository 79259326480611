import React from "react";

import { FormGroup, InputGroup, Callout, Intent } from "@blueprintjs/core";
import { AccountForm } from "../../molecules/AccountForms";
import { Link } from "react-router-dom";

import LinkHelper from "../../helpers/LinkHelper";

import styles from "../Login/LoginForm.module.scss";

function ForgotPasswordForm({ email, error, success, disabled, onEmailChange, onSubmit }) {
  const linkHelper = new LinkHelper();

  return (
    <AccountForm
      onSubmit={onSubmit}
      headerText="Forgot Password"
      buttonText="Send email"
      hideButton={success}
      disabled={disabled}
      bottomLinkComponent={
        <div className={styles.registerWrapper}>
          <p className={styles.registerText}>
            <Link to={linkHelper.appendRedirect("/login")}>Go Back</Link>
          </p>
        </div>
      }
    >
      {error && (
        <Callout
          intent={Intent.WARNING}
          title={error.title}
          style={{ marginBottom: "1em" }}
          icon={null}
        >
          {error.message}
        </Callout>
      )}

      {success && (
        <Callout intent={Intent.SUCCESS} title="Check your email!">
          We've sent you an email with a link to reset your password.
        </Callout>
      )}

      {!success && (
        <FormGroup label="Email Address" labelFor="forgot-email-input">
          <InputGroup id="forgot-email-input" type="email" value={email} onChange={onEmailChange} />
        </FormGroup>
      )}
    </AccountForm>
  );
}

export default ForgotPasswordForm;
