import React from "react";
import styles from "./Watch.module.scss";
import classNames from "classnames";
import * as marked from "marked";

marked.setOptions({
  renderer: new marked.Renderer(),
  pedantic: false,
  gfm: true,
  tables: true,
  breaks: false,
  sanitize: true,
  smartLists: true,
  smartypants: false,
  xhtml: false
});

function VideoDetails({ video, textStyle, headerStyle, product }) {
  return (
    <div className={styles.videoInfo}>
      <div className={styles.segment}>
        {product &&
          product.images &&
          product.images.map(image => <img className={styles.gallery} src={image.url} alt="" />)}
      </div>
      <div className={classNames(styles.segment, styles.flex1)}>
        <div className={styles.basicInfo}>
          <h4 style={headerStyle}>{product.name}</h4>
          <p style={headerStyle}>By {product.owner.name}</p>
        </div>

        <div
          style={textStyle}
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: marked(product.description) }}
        />
      </div>
    </div>
  );
}

export default VideoDetails;
