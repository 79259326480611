import React, { useState, useContext } from "react";
import {
  useCheckoutState,
  useCheckoutReducer,
  DONATION_PAGE
} from "../../../molecules/CheckoutDrawer/CheckoutDrawer";
import { useMixpanel } from "react-mixpanel-browser";
import VideoContext from "../../../context/VideoContext";
import CheckoutHelper from "../../../helpers/CheckoutHelper";

import CheckoutStep from "../../../molecules/Checkout/CheckoutStep";
import CheckoutContent from "../../../molecules/Checkout/CheckoutContent";
import CheckoutActions from "../../../molecules/Checkout/CheckoutActions";
import CheckoutButton from "../../../molecules/Checkout/CheckoutButton";
import DonationOptionList from "./DonationOptionList";
import { useDonationCheckout } from "./hooks";
import { FormGroup, InputGroup } from "@blueprintjs/core";

import styles from "./DonationCheckout.module.scss";

function DonationCheckout({ step, increaseStep, decreaseStep }) {
  const mixpanel = useMixpanel();
  const [{ videoLength, watchTime }] = useContext(VideoContext);
  const state = useCheckoutState();
  const { video, product } = state;
  const dispatch = useCheckoutReducer();

  const [donation, setDonation] = useState({ amount: null, other: null });

  const onNextClick = () => {
    dispatch({ type: "fulfilled", name: DONATION_PAGE, data: donation.amount });
    CheckoutHelper.trackDonationInfoProvided(
      { video, product, watchTime, videoLength, donationAmount: donation.amount },
      { mixpanel }
    );
    increaseStep();
  };

  const { minimumDonation } = useDonationCheckout({ product, donation });

  return (
    <CheckoutStep className={styles.donationCheckout}>
      <CheckoutContent>
        <DonationOptionList
          options={[15, 20, 50, 75, 100]}
          value={donation}
          onChange={setDonation}
        />
        {donation.other && (
          <FormGroup label="Custom Amount">
            <InputGroup
              large
              type="number"
              leftIcon="dollar"
              value={donation.amount}
              required
              placeholder="0.00"
              onChange={e => setDonation({ amount: e.target.value, other: true })}
            />
          </FormGroup>
        )}

        {donation.amount && (donation.amount < minimumDonation) && (
          <span className={styles.invalidDonation}>
            Minimum donation amount is ${minimumDonation}
          </span>
        )}
      </CheckoutContent>

      <CheckoutActions>
        <CheckoutButton disabled={!donation.amount} onClick={onNextClick}>
          Next
        </CheckoutButton>
      </CheckoutActions>
    </CheckoutStep>
  );
}

export default DonationCheckout;
