import React from "react";
import querystring from "querystring";
import { Switch, Redirect } from "react-router-dom";
import Route from "./Route";
import { Watch } from "../../pages/Watch";
import { Callback } from "../../pages/Callback";
import { Login } from "../../pages/Login";
import { Register } from "../../pages/Register";
import { ForgotPassword } from "../../pages/ForgotPassword";
import { Settings } from "../../pages/Settings";
import { Link } from "../../pages/Link";
import { Receipt } from "../../pages/Receipt";
import { VerifiedEmail } from "../../pages/VerifiedEmail";
import { VerifiedPassword } from "../../pages/VerifiedPassword";
import { NotFound } from "../../pages/Error";

function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={({ location }) => {
          const adjustedString = location.search.replace("?", "");
          const result = querystring.parse(adjustedString);

          if (result.supportSignUp === "true") {
            return <Redirect to="/verified" />;
          }

          window.location.href = "https://videosquirrel.app";
          return;
        }}
      />
      <Route exact path="/account/password-changed" component={VerifiedPassword} />

      <Route exact path="/verified" component={VerifiedEmail} />
      <Route exact path="/settings" render={() => <Redirect to="/settings/credit-card" />} />
      <Route path="/settings/:tabId" component={Settings} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/watch/:videoId" component={Watch} />
      <Route exact path="/callback" component={Callback} />
      <Route exact path="/link" component={Link} />
      <Route exact path="/order/:orderId" component={Receipt} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
