import React from "react";
import styles from "./Watch.module.scss";

function BgImage({ preference }) {
  const bgImg = preference && preference.backgroundImage;
  const bgColor = preference && preference.backgroundColor;
  const imgProps = {};
  if (bgImg) imgProps.src = bgImg;
  else if (bgColor) imgProps.style = { backgroundColor: bgColor };
  else imgProps.style = { background: `linear-gradient(137deg, #ff2b80, #ff6b6b)`, filter: "none" };

  return (
    <div className={styles.bgImage}>
      <img alt="" {...imgProps} />
    </div>
  );
}
export default BgImage;
