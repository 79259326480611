import React, { useRef, useState, useEffect, useContext } from "react";
import { useMixpanel } from "react-mixpanel-browser";

import Video from "./Video";
import VideoBackground from "./VideoBackground";
import VideoControls from "./VideoControls";
import VideoActions from "./VideoActions/VideoActions";
import VideoHud from "./VideoHud";
import VideoSchedule from "./VideoSchedule";
import VideoContext from "../../context/VideoContext";
import * as Hls from "hls.js";

import CheckoutHelper from "../../helpers/CheckoutHelper";
import styles from "./Player.module.scss";
import moment from "moment";

function Player({ video, onBuyClick, checkoutOpen }) {
  let hudTimeout;
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [hudIcon, setHudIcon] = useState("play");
  const [hudVisible, setHudVisible] = useState(false);
  const [videoState, setVideoState] = useContext(VideoContext);
  const mixpanel = useMixpanel();
  const videoEl = useRef();

  useEffect(() => {
    if (!video.live) return;

    const currentVideoEl = videoEl.current;
    if (!currentVideoEl) return;

    if (Hls.isSupported()) {
      var hls = new Hls();
      hls.attachMedia(currentVideoEl);
      // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
      hls.on(Hls.Events.MEDIA_ATTACHED, function() {
        hls.loadSource(video.url);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          currentVideoEl.play();
        });
      });
    } else if (currentVideoEl.canPlayType("application/vnd.apple.mpegurl")) {
      currentVideoEl.src = video.url;
      currentVideoEl.addEventListener("loadedmetadata", () => currentVideoEl.play());
    }
  }, [video]);

  useEffect(() => {
    const currentVideoEl = videoEl.current;
    if (!currentVideoEl) return;

    function handleKeyUp(e) {
      if (!checkoutOpen) {
        if (e.which === 39) skipVideo("forward");
        if (e.which === 37) skipVideo("back");
        if (e.which === 32) togglePlaying();
      }
    }

    function handleEnded(e) {
      const newVideoState = {
        videoLength: e.target.duration.toFixed(2),
        watchTime: e.target.currentTime.toFixed(2)
      };

      setVideoState(newVideoState);

      mixpanel.track(
        "Video Ended",
        CheckoutHelper.buildVideoProperties({ video, videoState: newVideoState })
      );
    }

    function handlePlay(e) {
      if (!isNaN(e.target.duration)) {
        setPlaying(true);
        currentVideoEl.play();

        mixpanel.track("Video Played", CheckoutHelper.buildVideoProperties({ video, videoState }));
      }
    }

    function handleDurationChange(e) {
      if (!isNaN(e.target.duration)) {
        const newVideoState = {
          videoLength: e.target.duration.toFixed(2),
          watchTime: e.target.currentTime.toFixed(2)
        };

        setVideoState(newVideoState);

        mixpanel.track(
          "Video Played",
          CheckoutHelper.buildVideoProperties({ video, videoState: newVideoState })
        );
      }
    }

    function handlePause(e) {
      if (!isNaN(e.target.duration)) {
        const newVideoState = {
          videoLength: e.target.duration.toFixed(2),
          watchTime: e.target.currentTime.toFixed(2)
        };
        setPlaying(false);
        setVideoState(newVideoState);
        currentVideoEl.pause();

        mixpanel.track(
          "Video Paused",
          CheckoutHelper.buildVideoProperties({ video, videoState: newVideoState })
        );
      }
    }

    function handleClose() {
      mixpanel.track(
        "Video Closed",
        CheckoutHelper.buildVideoProperties({
          video,
          videoState
        })
      );
    }

    function handleProgress(e) {
      setVideoState({
        videoLength: e.target.duration.toFixed(2),
        watchTime: e.target.currentTime.toFixed(2)
      });
    }

    currentVideoEl.addEventListener("ended", handleEnded);
    currentVideoEl.addEventListener("durationchange", handleDurationChange);
    currentVideoEl.addEventListener("play", handlePlay);
    currentVideoEl.addEventListener("pause", handlePause);
    currentVideoEl.addEventListener("timeupdate", handleProgress);
    document.addEventListener("keyup", handleKeyUp);
    window.addEventListener("beforeunload", handleClose);

    return () => {
      currentVideoEl.removeEventListener("ended", handleEnded);
      currentVideoEl.removeEventListener("durationchange", handleDurationChange);
      currentVideoEl.removeEventListener("play", handlePlay);
      currentVideoEl.removeEventListener("pause", handlePause);
      currentVideoEl.removeEventListener("timeupdate", handleProgress);
      document.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("beforeunload", handleClose);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing, checkoutOpen]);

  function videoProgressChange(value) {
    const currentVideoEl = videoEl.current;
    if (!currentVideoEl) return;

    currentVideoEl.currentTime = value;
  }

  function enterFullscreen() {
    const currentVideoEl = videoEl.current;
    if (!currentVideoEl) return;

    if (currentVideoEl.requestFullscreen) {
      currentVideoEl.requestFullscreen();
    } else if (currentVideoEl.mozRequestFullScreen) {
      currentVideoEl.mozRequestFullScreen();
    } else if (currentVideoEl.webkitRequestFullscreen) {
      currentVideoEl.webkitRequestFullscreen();
    } else if (currentVideoEl.msRequestFullscreen) {
      currentVideoEl.msRequestFullscreen();
    } else if (currentVideoEl.webkitEnterFullScreen) {
      currentVideoEl.webkitEnterFullScreen();
    }
  }

  function showHud(icon) {
    clearTimeout(hudTimeout);
    setHudIcon(icon);
    setHudVisible(true);
    hudTimeout = setTimeout(() => setHudVisible(false), 500);
  }

  function togglePlaying() {
    if (playing) showHud("pause");
    else showHud("play");
    setPlaying(!playing);
    if (!videoEl.current) return;
    if (!playing) videoEl.current.play();
    else videoEl.current.pause();
  }

  function skipVideo(direction) {
    const currentVideoEl = videoEl.current;
    if (!currentVideoEl) return;
    showHud(direction === "forward" ? "fast-forward" : "fast-backward");

    currentVideoEl.currentTime =
      direction === "forward" ? currentVideoEl.currentTime + 10 : currentVideoEl.currentTime - 10;
  }

  function handleDoubleClick(e) {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const percentage = x / rect.width;
    const direction = percentage > 0.5 ? "forward" : "back";
    skipVideo(direction);
  }

  const triggerEvent = (el, eventName, options) => {
    let event;
    if (window.CustomEvent) {
      event = new CustomEvent(eventName);
    } else {
      event = document.createEvent("CustomEvent");
      event.initCustomEvent(eventName, true, true, options);
    }
    togglePlaying();
    el.dispatchEvent(event);
  };

  if (video.scheduleStatus === "Enabled" && moment(video.scheduleStartTime).isAfter(moment())) {
    return <VideoSchedule scheduleStartTime={video.scheduleStartTime} />;
  }

  return (
    <div
      className={styles.player}
      onDoubleClick={handleDoubleClick}
      onClick={e => {
        if (e.target.tagName === "VIDEO") {
          playing
            ? triggerEvent(videoEl.current, "pause", {})
            : triggerEvent(videoEl.current, "play", {});
        }
      }}
    >
      <VideoActions video={video} onBuyClick={onBuyClick} />
      <VideoControls
        playing={playing}
        muted={muted}
        onTogglePlay={togglePlaying}
        onToggleMute={() => setMuted(!muted)}
        onFullscreen={enterFullscreen}
        onProgressChange={videoProgressChange}
        timeSpent={videoState.watchTime}
        duration={videoState.videoLength}
      />
      <VideoBackground thumbnailUrl={video.thumbnailUrl} />
      <Video url={video.url} thumbnailUrl={video.thumbnailUrl} muted={muted} ref={videoEl} />
      <VideoHud icon={hudIcon} visible={hudVisible || !playing} onTogglePlay={togglePlaying} />
    </div>
  );
}

export default Player;
