import { useState } from "react";
import { getMinimumDonation } from "./helpers";

export function useDonationCheckout({ product }) {
  const { fixedFee, variableFee } = product.owner;

  const { isPercentage, amount, isDiscontinued = true } =
    (product.opportunities && product.opportunities[product.opportunities.length - 1]) || {};

  const [minimumDonation, setMinimumDonation] = useState(5);
  // Here 0.01 is the amount to initialize the recursive calculation.
  const calculatedMinimum = isDiscontinued
    ? getMinimumDonation(variableFee, fixedFee)
    : getMinimumDonation(variableFee, fixedFee, isPercentage, amount);

  if (calculatedMinimum > minimumDonation) {
    setMinimumDonation(calculatedMinimum);
  }

  return {
    minimumDonation
  };
}
