import React, { useEffect } from "react";
import { useMixpanel } from "react-mixpanel-browser";

import { Route as RouterRoute } from "react-router-dom";

import { pathToPageName } from "./helpers";

function Route(props) {
  const { computedMatch } = props;
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (computedMatch) {
      const PageName = pathToPageName[computedMatch.path];

      mixpanel.track("Page Visited", {
        ...(PageName && { PageName }),
        Path: computedMatch.path,
        Parameters: computedMatch.params
      });
    }
  }, [computedMatch]);

  return <RouterRoute {...props} />;
}

export default Route;
