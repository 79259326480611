import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";

import { GetPaymentInfo } from "./queries";
import { DeletePaymentMethod } from "./mutations";

import { Spinner, Button, Intent, FormGroup } from "@blueprintjs/core";
import { Redirect } from "react-router-dom";
import { Belt } from "../../../layouts/Belt";
import SavedPaymentInfo from "../../../molecules/SavedPaymentInfo/SavedPaymentInfo";

import styles from "../Settings.module.scss";
import DefaultToaster from "../../../atoms/DefaultToaster";

function PaymentSettings() {
  const history = useHistory();
  const { data, loading } = useQuery(GetPaymentInfo);
  const [deleteCard] = useMutation(DeletePaymentMethod, {
    refetchQueries: [{ query: GetPaymentInfo }],
    awaitRefetchQueries: true
  });

  if (loading) {
    return (
      <Belt>
        <Spinner />
      </Belt>
    );
  }

  const paymentMethod = data?.accounts?.[0]?.paymentMethods?.[0];
  if (!paymentMethod) return <Redirect to="/settings/credit-card/edit" />;

  const onDelete = async () => {
    try {
      await deleteCard({
        variables: {
          cardId: paymentMethod.cardId
        }
      });

      DefaultToaster.show({
        intent: Intent.SUCCESS,
        message: "Card successfully removed from your account!"
      });
    } catch (err) {
      DefaultToaster.show({
        intent: Intent.DANGER,
        message: "Something went wrong! Contact support if the problem persists."
      });
    }
  };

  return (
    <div className={styles.panel}>
      <h4>Credit Card</h4>
      <p>Save your credit card to simplify your checkout experience in the future.</p>

      <FormGroup>
        <SavedPaymentInfo last4={paymentMethod.last4} type={paymentMethod.type} />
      </FormGroup>

      <div className={styles.buttonGroup}>
        <Button
          intent={Intent.PRIMARY}
          onClick={() => history.push("/settings/credit-card/edit")}
          text="Update Card"
        />

        <Button minimal intent={Intent.DANGER} onClick={onDelete} text="Remove Card" />
      </div>
    </div>
  );
}

export default PaymentSettings;
