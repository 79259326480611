import React, { useContext, useEffect, useReducer } from "react";
import { Drawer } from "@blueprintjs/core";
import { useResponsive } from "react-hooks-responsive";
import { Checkout } from "../../navigators/Checkout/Checkout";
import { CheckoutFactory } from "@zipline/checkout";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import styles from "./CheckoutDrawer.module.scss";

export const BILLING_PAGE = "Billing";
export const VARIANT_PAGE = "Variant";
export const POLITICAL_PAGE = "Political";
export const SHIPPING_PAGE = "ShippingAddress";
export const REVIEW_PAGE = "Review";
export const DONATION_PAGE = "Donation";

export const GET_ACCOUNT = gql`
  query AccountInfo {
    accounts {
      id

      paymentMethods {
        cardId
        type
        last4
        expirationYear
        expirationMonth
        isDefault
      }

      shippingMethods {
        id
        street1
        state {
          id
          name
        }
        postalCode
        city
        name
        isDefault
      }
    }
  }
`;

const CheckoutStateContext = React.createContext();
const CheckoutDispatchContext = React.createContext();

export function useCheckoutState() {
  const context = useContext(CheckoutStateContext);
  if (context === undefined) {
    throw new Error("useCheckoutState must be used within a CheckoutProvider");
  }
  return context;
}

export function useCheckoutReducer() {
  const context = useContext(CheckoutDispatchContext);
  if (context === undefined) {
    throw new Error("useCheckoutState must be used within a CheckoutProvider");
  }
  return context;
}

const checkoutFactory = new CheckoutFactory();

function checkoutReducer(state, action) {
  switch (action.type) {
    case "initialize":
      checkoutFactory.setProduct(action.product);
      checkoutFactory.setVideo(action.video);
      checkoutFactory.setSavedShippingInfo(action.savedAddress);
      checkoutFactory.setSavedBillingInfo(action.savedBillingInfo);
      const checkout = checkoutFactory.build();
      checkout.addProduct(action.product);
      return checkout;
    case "setContext":
      state.setContext(action.name, action.value);
      return state;
    case "fulfilled":
      state.fulfillRequirement(action.name, action.data);
      return state;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export function CheckoutDrawer({ product, video, isOpen, onClose, onCheckout, openCount }) {
  const { loading: accountLoading, data: accountData } = useQuery(GET_ACCOUNT);
  const [state, dispatch] = useReducer(checkoutReducer, null);
  const { size } = useResponsive({ xs: 0, sm: 480, md: 1024 });

  const closeSidebar = () => {
    onClose();
    onCheckout();
  };

  useEffect(() => {
    dispatch({
      type: "initialize",
      product,
      video,
      savedAddress: accountData?.shippingMethods,
      savedBillingInfo: accountData?.paymentMethods
    });
  }, [product, video, accountData, openCount]);

  return (
    <CheckoutStateContext.Provider value={{ ...state, video }}>
      <CheckoutDispatchContext.Provider value={dispatch}>
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          position={size === "xs" ? "bottom" : "right"}
          size={size === "xs" ? "70%" : Drawer.SIZE_SMALL}
          portalClassName={styles.CheckoutDrawerPortal}
        >
          <Checkout accountLoading={accountLoading} closeSidebar={closeSidebar} />
        </Drawer>
      </CheckoutDispatchContext.Provider>
    </CheckoutStateContext.Provider>
  );
}
