import React from "react";
import { TwoColumnPage } from "../../layouts/TwoColumnPage";
import RegisterExplanation from "./RegisterExplanation";
import RegisterForm from "./RegisterForm";
import useRegister from "./useRegister";

function Register() {
  const {
    name,
    username,
    email,
    password,
    error,
    disabled,
    mutationLoading,
    setName,
    setUsername,
    setEmail,
    setPassword,
    createAccount
  } = useRegister();

  return (
    <TwoColumnPage title="Create an Account">
      <RegisterExplanation />

      <RegisterForm
        name={name}
        username={username}
        email={email}
        password={password}
        error={error}
        disabled={disabled}
        mutationLoading={mutationLoading}
        onNameChange={e => setName(e.target.value)}
        onUsernameChange={e => setUsername(e.target.value)}
        onEmailChange={e => setEmail(e.target.value)}
        onPasswordChange={e => setPassword(e.target.value)}
        onSubmit={createAccount}
      />
    </TwoColumnPage>
  );
}

export default Register;
