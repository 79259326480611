import React from "react";
import moment from "moment";
import { Icon } from "@blueprintjs/core";
import BuyButton from "../../pages/Watch/BuyButton";

import styles from "./VideoInfo.module.scss";

function VideoInfo({ video, onBuyClick, closeHandler }) {
  return (
    <div className={styles.videoInfo}>
      <Icon icon="cross" iconSize={20} onClick={closeHandler} className={styles.closeButton} />
      <div className={styles.basicInfo}>
        <h4>{video.name}</h4>
      </div>

      <div className={styles.publisherInfo}>
        <img alt={video.owner.name} src={video.owner.picture} />
        <div>
          <p className={styles.name}>{video.owner.name}</p>
          <p>Published on {moment(new Date(video.createdAt)).format("MMM D, YYYY")}</p>
        </div>
      </div>

      {video.owner.bio && (
        <div className={styles.publisherBio}>
          <p>{video.owner.bio}</p>
        </div>
      )}

      <h5>Shown in this video...</h5>

      <div className={styles.productInfo}>
        <img alt={video.product.name} src={video.product.images[0].url} />
        <p className={styles.name}>{video.product.name}</p>
        <BuyButton
          price={
            video.product.isDonation
              ? "Donate"
              : Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  video.product.price
                )
          }
          video={video}
          onClick={onBuyClick}
        />
      </div>

      <div className={styles.help}>
        <Icon icon="info-sign" color="#909bad" />
        <p>
          <small>
            {video.product.isDonation
              ? `You can donate to ${video.product.owner.name} without leaving this video!`
              : `You can buy products straight from ${video.product.owner.name} without leaving this
          video!`}
          </small>
        </p>
      </div>
    </div>
  );
}

export default VideoInfo;
