import React from "react";
import CheckoutLoading from "../../pages/Checkout/CheckoutLoading";

function StepNavigator({ step, screens, increaseStep, decreaseStep, setStep, closeSidebar }) {
  const Screen = screens[step] ? screens[step].screen : CheckoutLoading;
  return (
    <Screen
      requirements={screens.map(elem => elem.title)}
      increaseStep={increaseStep}
      decreaseStep={decreaseStep}
      setStep={setStep}
      step={step}
      closeSidebar={closeSidebar}
    />
  );
}

export default StepNavigator;
