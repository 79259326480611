import _ from "lodash";

/**
 * Converts variants returned from GraphQL into a structure that is easier to manipulate.
 * @param {*} variants
 */
export const consolidateVariants = variants => {
  return variants.map(v => ({
    id: v.id,
    options: Object.assign({}, ...v.selectedValues.map(({ id, option }) => ({ [option.id]: id })))
  }));
};

/**
 * Private
 * Returns all options and their selected values except for the option specified.
 * @param {*} selections
 * @param {*} optionId
 */
export const getOtherSelections = (selections, optionId) => {
  return _.pickBy(selections, (value, key) => key !== optionId);
};

/**
 * Checks if a value is disabled/unavailable.
 * @param {*} variants
 * @param {*} selections
 * @param {*} optionId
 * @param {*} valueId
 */
export const isValueDisabled = (variants, selections, optionId, valueId) => {
  const otherSelectedValues = Object.values(getOtherSelections(selections, optionId));

  const variantsMatchingOtherSelections = variants.filter(v =>
    otherSelectedValues.every(ov => Object.values(v.options).includes(ov))
  );

  const filtered = variantsMatchingOtherSelections.filter(v =>
    Object.values(v.options).includes(valueId)
  );

  return filtered.length === 0;
};

/**
 * Private
 * Gets unique value IDs from a list of variants
 * @param {*} variants
 */
export const getVariantValues = variants => {
  return _.chain(variants)
    .map(v => Object.values(v.options))
    .flatten()
    .uniq()
    .value();
};

/**
 * Filters the specified values, returning only the values that can be possibly satisfied by the specified variants.
 * @param {*} variants
 * @param {*} values
 */
export const filterImpossibleValues = (variants, values) => {
  const variantValues = getVariantValues(variants);
  return values.filter(v => variantValues.includes(v.id));
};

/**
 * Manages the state of the selected variant values when a value is clicked
 */
export const onSelectValueHof = ({
  product,
  variants,
  selections,
  consolidatedVariants,
  setSelections,
  setVariant
}) => ({ event, optionId, valueId }) => {
  event.preventDefault();
  // Update selections to match what was clicked
  const newSelections =
    selections[optionId] === valueId
      ? _.omit(selections, optionId)
      : { ...selections, [optionId]: valueId };

  // Remove selections that are now disabled
  const isDisabled = isValueDisabled(consolidatedVariants, newSelections, optionId, valueId);

  setSelections(
    isDisabled ? _.omitBy(newSelections, (value, key) => key !== optionId) : newSelections
  );

  // Remove variants that don't match _all_ selections
  const newVariants = consolidatedVariants.filter(v =>
    Object.values(newSelections).every(s => Object.values(v.options).includes(s))
  );

  // Trigger the onChange event
  setVariant(
    newVariants.length === 1 && Object.keys(newSelections).length === product.options.length
      ? variants.find(v => v.id === newVariants[0].id)
      : null
  );
};
