import React from "react";
import styles from "./Belt.module.scss";

function Belt({ children, width }) {
  return (
    <div className={styles.belt} style={{ maxWidth: width || 950 }}>
      {children}
    </div>
  );
}

export default Belt;
