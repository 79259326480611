import React, { useState, useEffect } from "react";
import { useMixpanel } from "react-mixpanel-browser";

import Auth0 from "../../helpers/Auth0";
import AuthContext, { defaultAuthState } from "../../context/UserContext";
import { Belt } from "../../layouts/Belt";
import { Spinner } from "@blueprintjs/core";

function Auth({ children }) {
  const auth0 = new Auth0();

  const [authState, setAuthState] = useState(defaultAuthState);

  const mixpanel = useMixpanel();

  const login = async (email, password, alias) => {
    try {
      const result = await auth0.login(email, password);
      const user = await auth0.getUserInfo(result.accessToken);

      setAuthState({
        isLoggedIn: true,
        loading: false,
        user,
        token: result.idToken,
        logout,
        login
      });

      try {
        window.localStorage.setItem("authSession", JSON.stringify(result));
      } catch (e) {
        console.log("security_issue", e);
      }

      if (!alias) {
        mixpanel.identify(user.sub);
        mixpanel.people.set(user);
      } else {
        mixpanel.alias(user.sub);
        mixpanel.people.set(user);
      }
      mixpanel.track("Logged In");
    } catch (err) {
      if (err.code === "invalid_grant") {
        throw new Error("invalid_credentials");
      } else {
        throw err;
      }
    }
  };

  const logout = () => {
    try {
      window.localStorage.removeItem("authSession");
    } catch (e) {
      console.log("security_issue", e);
    }

    setAuthState({
      isLoggedIn: false,
      loading: false,
      user: null,
      logout,
      login
    });

    mixpanel.track("Logged Out");
    mixpanel.reset();
  };

  useEffect(() => {
    const loadAuthSession = async () => {
      try {
        const { idToken, accessToken } = await auth0.getSavedSession();
        const user = await auth0.getUserInfo(accessToken);

        setAuthState({
          isLoggedIn: true,
          loading: false,
          user,
          token: idToken,
          accessToken,
          logout,
          login
        });
      } catch (err) {
        if (err.message !== "not_logged_in") {
          console.error("login_error", err);
        }

        try {
          window.localStorage.removeItem("authSession");
        } catch (e) {
          console.log("security_issue", e);
        }

        setAuthState({
          isLoggedIn: false,
          loading: false,
          user: null,
          logout,
          login
        });
      }
    };

    loadAuthSession();
  }, []);

  return (
    <AuthContext.Provider value={authState}>
      {authState.loading && (
        <div className="loadingContainer">
          <Belt centered width={300}>
            <Spinner />
          </Belt>
        </div>
      )}

      {!authState.loading && children}
    </AuthContext.Provider>
  );
}

export default Auth;
