import React from "react";
import StripeBillingCheckout from "./StripeBillingCheckout";
import { useCheckoutState } from "../../../molecules/CheckoutDrawer/CheckoutDrawer";
import NmiBillingCheckout from "./NmiBillingCheckout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
  apiVersion: "2020-08-27"
});

function BillingCheckout({ increaseStep }) {
  const state = useCheckoutState();
  const { product } = state;
  const { isNmiAccount } = product.owner || {};
  console.log(stripePromise);

  return !isNmiAccount ? (
    <Elements stripe={stripePromise}>
      <StripeBillingCheckout increaseStep={increaseStep} />
    </Elements>
  ) : (
    <NmiBillingCheckout increaseStep={increaseStep} />
  );
}

export default BillingCheckout;
