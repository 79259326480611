import React from "react";

import { FormGroup, InputGroup, Callout, Intent } from "@blueprintjs/core";
import { AccountForm } from "../../molecules/AccountForms";
import { Link } from "react-router-dom";

import LinkHelper from "../../helpers/LinkHelper";

import styles from "../Login/LoginForm.module.scss";

function RegisterForm({
  name,
  username,
  email,
  password,
  error,
  disabled,
  onNameChange,
  onUsernameChange,
  onEmailChange,
  onPasswordChange,
  onSubmit,
  mutationLoading
}) {
  const linkHelper = new LinkHelper();

  const topLinkComponent = (
    <div className={styles.registerWrapper}>
      <p className={styles.registerText}>
        By continuing, you agree to our&nbsp;
        <a href="https://videosquirrel.app/tos" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{" "}
        and&nbsp;
        <a href="https://videosquirrel.app/privacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        .
      </p>
    </div>
  );

  const bottomLinkComponent = (
    <div className={styles.bottomTextWrapper}>
      <p className={styles.registerText}>
        Already have a VideoSquirrel Account?&nbsp;
        <Link to={linkHelper.appendRedirect("/login")}>Login</Link>
      </p>
    </div>
  );

  return (
    <AccountForm
      onSubmit={onSubmit}
      headerText="Add Your Profile Information"
      buttonText="Sign Up"
      topLinkComponent={topLinkComponent}
      bottomLinkComponent={bottomLinkComponent}
      disabled={disabled}
      loading={mutationLoading}
    >
      {error && (
        <Callout
          intent={Intent.WARNING}
          title={error.title}
          style={{ marginBottom: "1em" }}
          icon={null}
        >
          {error.message}
        </Callout>
      )}

      <FormGroup label="Name" labelFor="name">
        <InputGroup id="name" type="text" value={name || ""} onChange={onNameChange} />
      </FormGroup>

      <FormGroup label="Contact Email" labelFor="email">
        <InputGroup id="email" type="email" value={email} onChange={onEmailChange} />
      </FormGroup>

      <FormGroup label="Username" labelFor="username">
        <InputGroup
          id="username"
          type="text"
          autoComplete="false"
          autoCapitalize="none"
          value={username}
          onChange={onUsernameChange}
        />
      </FormGroup>

      <FormGroup label="Password" labelFor="password-input">
        <InputGroup
          id="password-input"
          type="password"
          value={password}
          onChange={onPasswordChange}
        />
      </FormGroup>
    </AccountForm>
  );
}

export default RegisterForm;
