import React from "react";
import {
  POLITICAL_PAGE,
  useCheckoutState,
  useCheckoutReducer
} from "../../../molecules/CheckoutDrawer/CheckoutDrawer";
import { usePoliticalCheckout } from "./hooks";

import { InputGroup, FormGroup, Checkbox } from "@blueprintjs/core";
import CheckoutStep from "../../../molecules/Checkout/CheckoutStep";
import CheckoutContent from "../../../molecules/Checkout/CheckoutContent";
import CheckoutActions from "../../../molecules/Checkout/CheckoutActions";
import CheckoutButton from "../../../molecules/Checkout/CheckoutButton";
import styles from "./PoliticalCheckout.module.scss";
import * as marked from "marked";
import { HTMLSelect as Select } from "@blueprintjs/core/lib/esm/components/html-select/htmlSelect";

marked.setOptions({
  renderer: new marked.Renderer(),
  pedantic: false,
  gfm: true,
  tables: true,
  breaks: false,
  sanitize: true,
  smartLists: true,
  smartypants: false,
  xhtml: false
});

function PoliticalCheckout({ increaseStep }) {
  const state = useCheckoutState();
  const { video, product } = state;
  const dispatch = useCheckoutReducer();
  const { data } = state.requirements[POLITICAL_PAGE];

  const {
    employer,
    setEmployer,
    occupation,
    setOccupation,
    city,
    setCity,
    streetName,
    setStreetName,
    statesList,
    retired,
    handleRetirementChange,
    addressState,
    setAddressState,
    postalCode,
    setPostalCode,
    isFormValid,
    onNextClick
  } = usePoliticalCheckout({ data, dispatch, increaseStep, name: POLITICAL_PAGE, video, product });

  return (
    <CheckoutStep>
      <CheckoutContent>
        <FormGroup label="Campaign finance law requires us to collect your employment information...">
          <div className={styles.formRow}>
            <Checkbox label="I am retired." checked={retired} onChange={handleRetirementChange} />
          </div>

          <FormGroup label="Occupation">
            <InputGroup
              placeholder="Occupation"
              value={occupation}
              onChange={setOccupation}
              disabled={retired}
              autoFocus
              required
              large
            />
          </FormGroup>

          <FormGroup label="Employer">
            <InputGroup
              placeholder="Employer"
              value={employer}
              onChange={setEmployer}
              disabled={retired}
              required
              large
            />
          </FormGroup>

          <FormGroup label="Employer Address">
            <InputGroup
              placeholder="Street Name"
              value={streetName}
              onChange={setStreetName}
              disabled={retired}
              required
              large
            />
          </FormGroup>

          <div className={styles.formRow}>
            <FormGroup label="City">
              <InputGroup
                placeholder="City"
                value={city}
                onChange={setCity}
                disabled={retired}
                required
                large
              />
            </FormGroup>

            <FormGroup label="State" className={styles.fldSelect}>
              <Select
                options={statesList}
                value={addressState}
                disabled={retired}
                onChange={setAddressState}
              />
            </FormGroup>

            <FormGroup label="Zip Code">
              <InputGroup
                placeholder="Zip Code"
                value={postalCode}
                onChange={setPostalCode}
                disabled={retired}
                required
                large
              />
            </FormGroup>
          </div>
        </FormGroup>

        <FormGroup label="Additionally, by clicking agree, you certify that...">
          <div
            dangerouslySetInnerHTML={{ __html: marked(video.owner.politicalDisclaimer) }}
            className={styles.agreementDetail}
          ></div>
        </FormGroup>
      </CheckoutContent>

      <CheckoutActions>
        <CheckoutButton disabled={!isFormValid} onClick={onNextClick}>
          Agree
        </CheckoutButton>
      </CheckoutActions>
    </CheckoutStep>
  );
}

export default PoliticalCheckout;
