import React from "react";
import { Redirect } from "react-router-dom";
import Route from "./Route";
import useAuthState from "../../hooks/useAuthState";

function ProtectedRoute({ path, exact, component: Component, ...props }) {
  const { isLoggedIn } = useAuthState();

  return (
    <Route
      path={path}
      exact={exact}
      render={props => {
        if (isLoggedIn) {
          return <Component {...props} />;
        } else if (!isLoggedIn) {
          return <Redirect to={`/login?redirect=${path}`} />;
        }
      }}
      {...props}
    />
  );
}

export default ProtectedRoute;
