import gql from "graphql-tag";

export const GET_ORDER_BY_ID = gql`
  query GetOrderById($id: String!, $viewingToken: String) {
    orders(id: $id, viewingToken: $viewingToken) {
      id
      charge
      amount
      createdAt
      card
      last4
      email
      variantOptions
      customerNote
      quantity

      buyer {
        id
        name
        username
        picture
      }

      seller {
        id
        name
        username
        email
      }

      destination {
        id
        name
        street1
        street2
        city
        state {
          id
          name
        }
        postalCode
        country
      }

      product {
        id
        name
        description
        isDonation

        owner {
          id
          name
          supportEmail
          policies {
            privacyPolicyUrl
            termsOfServiceUrl
            refundPolicyUrl
          }
          website
          picture
          statementDescriptor
        }

        parent {
          id
          name
          description

          images {
            id
            url
          }
        }

        images {
          id
          url
        }
      }
    }
  }
`;
