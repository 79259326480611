import React from "react";
import styles from "./CheckoutStep.module.scss";
import classNames from "classnames";
import { Icon } from "@blueprintjs/core";

function CheckoutStep({ step, isCurrentStep, isRightStep, setStep, name, isLastStep }) {
  return [
    <div
      className={classNames(
        styles.circleWrapper,
        isCurrentStep && styles.currentStep,
        isRightStep && styles.rightStep
      )}
      onClick={!isCurrentStep && !isRightStep ? () => setStep(step - 1) : () => {}}
    >
      <span className={styles.circleNumber}>
        {isLastStep ? <Icon iconSize={16} icon="small-tick" /> : step}
      </span>
    </div>,
    isCurrentStep && (
      <div className={styles.label}>
        <span className={styles.labelText}>{name}</span>
      </div>
    )
  ];
}

export default CheckoutStep;
