import React from "react";
import { useNmiBillingCheckoutForm } from "./hooks";
import {
  BILLING_PAGE,
  useCheckoutReducer,
  useCheckoutState
} from "../../../molecules/CheckoutDrawer/CheckoutDrawer";

import { FormGroup, InputGroup, Intent, Icon } from "@blueprintjs/core";
import CheckoutActions from "../../../molecules/Checkout/CheckoutActions";
import CheckoutButton from "../../../molecules/Checkout/CheckoutButton";
import CheckoutContent from "../../../molecules/Checkout/CheckoutContent";
import CheckoutStep from "../../../molecules/Checkout/CheckoutStep";

import CvvInfoPopover from "./CvvInfoPopover";
import SecureInfoPopover from "./SecureInfoPopover";

import classNames from "classnames";
import styles from "./BillingCheckout.module.scss";

function NmiBillingCheckout({ increaseStep }) {
  const state = useCheckoutState();
  const dispatch = useCheckoutReducer();
  const { video, product } = state;
  const { data } = state.requirements[BILLING_PAGE];

  const {
    cardNumberError,
    cardNameError,
    expirationDateError,
    cvvError,
    cardholderName,
    setCardHolderName,
    onNext,
    formLoading,
    isSubmitLoading,
    submitDisabled
  } = useNmiBillingCheckoutForm({
    data: data || {},
    dispatch,
    increaseStep,
    name: BILLING_PAGE,
    video,
    product
  });

  return (
    <CheckoutStep>
      <CheckoutContent>
        <FormGroup
          label="Card Number"
          helperText={cardNumberError.message}
          intent={cardNumberError.error ? Intent.DANGER : Intent.NONE}
        >
          <div className="bp3-input-group">
            <Icon icon="credit-card" style={{ margin: 12 }} />
            <div
              id="ccnumber"
              disabled={formLoading}
              className={classNames(
                styles.nmiInputContainer,
                formLoading && styles.formLoading,
                styles.cardInput
              )}
            >
              <SecureInfoPopover isNmiAccount={true} />
            </div>
          </div>
        </FormGroup>

        <FormGroup
          label="Name on Card"
          helperText={cardNameError.message}
          intent={cardNameError.error ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            value={cardholderName}
            onChange={setCardHolderName}
            placeholder="Cardholder Name"
            leftIcon="user"
            large
            fill
          />
        </FormGroup>

        <div className={styles.expirationAndCvv}>
          <FormGroup
            label="Expiration Date"
            helperText={expirationDateError.message}
            intent={expirationDateError.error ? Intent.DANGER : Intent.NONE}
            className={styles.expiration}
          >
            <div className="bp3-input-group">
              <Icon icon="calendar" style={{ margin: 12 }} />
              <div
                id="ccexp"
                disabled={formLoading}
                className={classNames(styles.nmiInputContainer, formLoading && styles.formLoading)}
              ></div>
            </div>
          </FormGroup>

          <FormGroup
            label="CVV"
            helperText={cvvError.message}
            intent={cvvError.error ? Intent.DANGER : Intent.NONE}
            className={styles.cvv}
          >
            <div
              id="cvv"
              disabled={formLoading}
              className={classNames(
                styles.nmiInputContainer,
                styles.cvvInput,
                formLoading && styles.formLoading
              )}
            >
              <CvvInfoPopover />
            </div>
          </FormGroup>
        </div>

        <div className={styles.securityBadges}>
          <img src={require("./nmi_security.svg")} alt="" />
        </div>
      </CheckoutContent>

      <CheckoutActions>
        <CheckoutButton loading={isSubmitLoading} disabled={submitDisabled} onClick={onNext}>
          Next
        </CheckoutButton>
      </CheckoutActions>
    </CheckoutStep>
  );
}

export default NmiBillingCheckout;
