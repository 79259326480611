import React, { useContext, useEffect, useState } from "react";
import { useStripeBillingCheckoutForm } from "./hooks";
import {
  BILLING_PAGE,
  DONATION_PAGE,
  SHIPPING_PAGE,
  useCheckoutReducer,
  useCheckoutState
} from "../../../molecules/CheckoutDrawer/CheckoutDrawer";

import CheckoutHelper from "../../../helpers/CheckoutHelper";
import { PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js";
import { Classes, FormGroup, InputGroup, Intent, Icon } from "@blueprintjs/core";
import Cleave from "cleave.js/react";
import CheckoutActions from "../../../molecules/Checkout/CheckoutActions";
import CheckoutButton from "../../../molecules/Checkout/CheckoutButton";
import CheckoutContent from "../../../molecules/Checkout/CheckoutContent";
import CheckoutStep from "../../../molecules/Checkout/CheckoutStep";

import CvvInfoPopover from "./CvvInfoPopover";
import SecureInfoPopover from "./SecureInfoPopover";

import classNames from "classnames";
import styles from "./BillingCheckout.module.scss";
import VideoContext from "../../../context/VideoContext";
import { useMixpanel } from "react-mixpanel-browser";
import { useApolloClient } from "@apollo/react-hooks";
import { GET_STATE_BY_CODE } from "./queries";

function StripeBillingCheckout({ increaseStep }) {
  const stripe = useStripe();
  const state = useCheckoutState();
  const dispatch = useCheckoutReducer();
  const mixpanel = useMixpanel();
  const [{ videoLength, watchTime }] = useContext(VideoContext);
  const { video, product } = state;
  const { data } = state.requirements[BILLING_PAGE];
  const donation = state.requirements[DONATION_PAGE];
  const [stripePaymentRequest, setStripePaymentRequest] = useState(null);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: state.product.owner.name,
          amount: state.product.isDonation
            ? donation.data * 100
            : state.product.price.toFixed(2) * 100
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestShipping: true,
        shippingOptions: [
          {
            id: "free-shipping",
            label: "Free shipping",
            detail: "",
            amount: 0
          }
        ]
      });

      pr.canMakePayment().then(result => {
        if (result) {
          dispatch({ type: "setContext", name: "stripePaymentRequest", value: pr });
          setStripePaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  useEffect(() => {
    if (stripePaymentRequest && apolloClient) {
      stripePaymentRequest.on("paymentmethod", async ev => {
        dispatch({
          type: "fulfilled",
          name: BILLING_PAGE,
          data: {
            cardNumber: "",
            cardholderName: ev.payerName,
            cvv: "",
            expiration: `${ev.paymentMethod.card.exp_month}/${ev.paymentMethod.card.exp_year}`,
            last4: ev.paymentMethod.card.last4,
            brand: ev.paymentMethod.card.brand,
            token: ev.paymentMethod.id,
            paymentRequestButton: true
          }
        });

        CheckoutHelper.trackBillingInfoProvided(
          {
            video,
            product,
            watchTime,
            videoLength
          },
          {
            mixpanel
          }
        );

        const { states } = await apolloClient
          .query({ query: GET_STATE_BY_CODE, variables: { code: ev.shippingAddress.region } })
          .then(r => r.data);

        if (states.length > 0) {
          dispatch({
            type: "fulfilled",
            name: SHIPPING_PAGE,
            data: {
              suffix: "",
              firstName: ev.payerName.split(" ")[0],
              middleInitial: "",
              lastName: ev.payerName.split(" ")[1],
              email: ev.payerEmail,
              streetName: ev.shippingAddress.addressLine.join(" "),
              city: ev.shippingAddress.city,
              addressState: states[0].id,
              addressCountry: "1",
              postalCode: ev.shippingAddress.postalCode,
              customerNote: ""
            }
          });
        }

        CheckoutHelper.trackShippingInfoProvided(
          { video, product, watchTime, videoLength },
          { mixpanel }
        );

        increaseStep();

        ev.complete("success");
      });
    }
  }, [stripePaymentRequest, apolloClient]);

  const {
    cardNumber,
    setCardNumber,
    cardholderName,
    setCardHolderName,
    expiration,
    setExpiration,
    cvv,
    setCvv,
    error,
    isCardNumberValid,
    isCardNameValid,
    isExpirationDateValid,
    isCvvValid,
    isFormValid,
    onNext
  } = useStripeBillingCheckoutForm({
    data: data || {},
    dispatch,
    increaseStep,
    name: BILLING_PAGE,
    video,
    product,
    stripePaymentRequest
  });

  return (
    <CheckoutStep>
      <CheckoutContent>
        {stripePaymentRequest && (
          <div style={{ marginBottom: 15 }}>
            <PaymentRequestButtonElement
              options={{
                paymentRequest: stripePaymentRequest,
                style: {
                  paymentRequestButton: {
                    type: state.product.isDonation ? "donate" : "default"
                  }
                }
              }}
            />
          </div>
        )}
        <FormGroup
          label="Card Number"
          helperText={error.message}
          intent={isCardNumberValid ? Intent.NONE : Intent.DANGER}
        >
          <InputGroup
            value={cardNumber}
            onChange={setCardNumber}
            type="tel"
            inputMode="numeric"
            pattern="[0-9\s]{13,19}"
            autoComplete="cc-number"
            placeholder="4242 4242 4242 4242"
            leftIcon="credit-card"
            rightElement={<SecureInfoPopover />}
            autoFocus
            large
            fill
          />
        </FormGroup>

        <FormGroup
          label="Name on Card"
          helperText={!isCardNameValid && error.message}
          intent={isCardNumberValid ? Intent.NONE : Intent.DANGER}
        >
          <InputGroup
            value={cardholderName}
            onChange={setCardHolderName}
            placeholder="Cardholder Name"
            leftIcon="user"
            large
            fill
          />
        </FormGroup>

        <div className={styles.expirationAndCvv}>
          <FormGroup
            label="Expiration Date"
            helperText={!isExpirationDateValid && error.message}
            intent={isExpirationDateValid ? Intent.NONE : Intent.DANGER}
            className={styles.expiration}
          >
            <div className="bp3-input-group">
              <Icon icon="calendar" style={{ margin: 12 }} />

              <Cleave
                type="tel"
                pattern="[0-9]*"
                options={{ date: true, datePattern: ["m", "y"] }}
                onChange={setExpiration}
                value={expiration}
                placeholder="MM/YY"
                style={{ paddingLeft: 40 }}
                className={classNames(Classes.INPUT, Classes.LARGE)}
              />
            </div>
          </FormGroup>

          <FormGroup
            label="CVV"
            helperText={!isCvvValid && error.message}
            intent={isCvvValid ? Intent.NONE : Intent.DANGER}
            className={styles.cvv}
          >
            <InputGroup
              type="tel"
              pattern="[0-9]*"
              value={cvv}
              onChange={setCvv}
              placeholder="CVV"
              rightElement={<CvvInfoPopover />}
              large
            />
          </FormGroup>
        </div>

        <div className={styles.securityBadges}>
          <img src={require("./security.svg")} alt="" />
        </div>
      </CheckoutContent>

      <CheckoutActions>
        <CheckoutButton disabled={!isFormValid} onClick={onNext}>
          Next
        </CheckoutButton>
      </CheckoutActions>
    </CheckoutStep>
  );
}

export default StripeBillingCheckout;
