import React from "react";
import CheckoutStepNavigator from "./CheckoutStepNavigator";

function Checkout({ accountLoading, closeSidebar }) {
  if (accountLoading) {
    return <div>Loading...</div>;
  }

  return <CheckoutStepNavigator closeSidebar={closeSidebar} />;
}

export { Checkout };
