import React from "react";
import { usePreferences } from "./hooks";

import { Spinner, Switch, Alignment } from "@blueprintjs/core";
import { Belt } from "../../../layouts/Belt";

import { getPreferenceDetails } from "./helpers";

import styles from "../Settings.module.scss";

function NotificationSettings() {
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");

  const { loading, preferences, onPreferenceChange } = usePreferences(token);

  if (loading) {
    return (
      <Belt>
        <Spinner />
      </Belt>
    );
  }

  const brandPreferences = Object.keys(preferences).filter(p => p.startsWith("brand"));
  const influencerPreferences = Object.keys(preferences).filter(p => p.startsWith("influencer"));

  return (
    <div className={styles.panel}>
      <h4>Your Products</h4>
      <p>These notification preferences refer to any products you have created.</p>

      {brandPreferences.map(key => (
        <>
          <Switch
            key={key}
            checked={preferences[key]}
            label={getPreferenceDetails(key).label}
            alignIndicator={Alignment.RIGHT}
            onChange={event => onPreferenceChange(key, event)}
          />
          <p>{getPreferenceDetails(key).description}</p>
        </>
      ))}

      <h4>Partnered Products</h4>
      <p>These notification preferences refer to any opportunities you have partnered with.</p>

      {influencerPreferences.map(key => (
        <>
          <Switch
            key={key}
            checked={preferences[key]}
            label={getPreferenceDetails(key).label}
            alignIndicator={Alignment.RIGHT}
            onChange={event => onPreferenceChange(key, event)}
          />
          <p>{getPreferenceDetails(key).description}</p>
        </>
      ))}
    </div>
  );
}

export default NotificationSettings;
