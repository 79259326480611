class LinkHelper {
  get redirectParam() {
    const params = new URLSearchParams(window.location.search);
    return params.get("redirect");
  }

  appendRedirect(link) {
    const redirect = this.redirectParam;
    return `${link}${redirect ? `?redirect=${redirect}` : ""}`;
  }
}

export default LinkHelper;
