import React from "react";
import styles from "./VariantCheckout.module.scss";
import { Tag } from "@blueprintjs/core";

export default function SelectedVariant({ variant }) {
  return (
    <div className={styles.selectedVariant}>
      <img className={styles.variantImage} alt={variant.name} src={variant.images[0].url} />
      <div className={styles.variantInfo}>
        <div className={styles.variantName}>
          <p>{variant.name}</p>
          <span>
            {Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
              variant.price
            )}
          </span>
        </div>
        <div className={styles.selectedOptions}>
          {variant.selectedValues.map(({ id, name }) => (
            <Tag key={id} minimal small>
              {name}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  );
}
