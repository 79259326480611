import React from "react";
import styles from "./DonationCheckout.module.scss";
import classNames from "classnames";

function DonationOption({ label, selected, onClick }) {
  return (
    <button
      className={classNames({
        [styles.selected]: selected
      })}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

function DonationOptionSet({ options, value, onChange }) {
  return (
    <div className={styles.donationOptionSet}>
      <label>Donation Amount</label>
      <div className={styles.donationValues}>
        {options.map((option, index) => (
          <DonationOption
            key={index}
            label={`$${option}`}
            selected={value.amount === option}
            onClick={() => onChange({ amount: option, other: false })}
          />
        ))}

        <DonationOption
          label="Other"
          selected={value.other}
          onClick={() => onChange({ amount: null, other: true })}
        />
      </div>
    </div>
  );
}

export default function DonationOptionList({ options, value, onChange }) {
  return (
    <div className={styles.donationOptionList}>
      <DonationOptionSet options={options} value={value} onChange={onChange} />
    </div>
  );
}
