import React, { useState } from "react";

import ForgotPasswordExplanation from "./ForgotPasswordExplanation";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { TwoColumnPage } from "../../layouts/TwoColumnPage";

import Auth0 from "../../helpers/Auth0";
import { isEmail } from "validator";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const disabled = email === "" || !isEmail(email);

  const sendPasswordReset = async e => {
    e.preventDefault();
    try {
      const auth0 = new Auth0();
      await auth0.sendPasswordReset(email);
      setSuccess(true);
    } catch (err) {
      setError({
        title: "Something went wrong.",
        message:
          "We apologize, we couldn't reset your password at this time. Please try again in a few minutes."
      });

      throw err;
    }
  };

  return (
    <TwoColumnPage title="Forgot Password">
      <ForgotPasswordExplanation />

      <ForgotPasswordForm
        email={email}
        error={error}
        success={success}
        disabled={disabled}
        onSubmit={sendPasswordReset}
        onEmailChange={e => setEmail(e.target.value)}
      />
    </TwoColumnPage>
  );
}

export default ForgotPassword;
