import React from "react";
import styles from "./LeftExplanation.module.scss";

function LeftExplanation({ icon, title, explanation }) {
  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <img className={styles.registrationImage} alt="explanation decorator" src={icon} />
      </div>
      <div>
        <h2 className={styles.header}>{title}</h2>
      </div>
      <div>
        <p className={styles.paragraph}>{explanation}</p>
      </div>
    </div>
  );
}

export default LeftExplanation;
