import React, { useContext } from "react";
import { useMixpanel } from "react-mixpanel-browser";
import CheckoutHelper from "../../helpers/CheckoutHelper";
import VideoContext from "../../context/VideoContext";

import { Button, Popover, Classes, Position, Drawer } from "@blueprintjs/core";
import VideoContent from "./VideoContent";

import styles from "./VideoInfo.module.scss";
import classNames from "classnames";

function VideoInfo({ video, isLargeScreen, onBuyClick, componentName, onDrawerClose, setDialog }) {
  const mixpanel = useMixpanel();
  const [videoState] = useContext(VideoContext);

  const onPopoverVisibilityChange = event => {
    mixpanel.track(event, CheckoutHelper.buildVideoProperties({ video, videoState }));
  };

  return (
    <>
      {isLargeScreen ? (
        <Popover
          popoverClassName={classNames(Classes.POPOVER_CONTENT_SIZING, styles.videoInfo)}
          position={Position.TOP_RIGHT}
          onOpening={() => onPopoverVisibilityChange("Video Info Opened")}
          onClosing={() => onPopoverVisibilityChange("Video Info Closed")}
          isOpen={componentName === "VideoInfo"}
        >
          <Button
            minimal
            icon="info-sign"
            className={styles.withColorHover}
            onClick={() => setDialog("VideoInfo")}
          >
            Info
          </Button>
          <VideoContent video={video} onBuyClick={onBuyClick} closeHandler={onDrawerClose} />
        </Popover>
      ) : (
        <>
          <Drawer
            className={styles.drawer}
            position={Position.BOTTOM}
            size="75%"
            isOpen={componentName === "VideoInfo"}
            onClose={onDrawerClose}
          >
            <VideoContent video={video} onBuyClick={onBuyClick} closeHandler={onDrawerClose} />
          </Drawer>
        </>
      )}
    </>
  );
}

export default VideoInfo;
