import React, { useState } from "react";
import { Drawer, Icon, Position } from "@blueprintjs/core";
import styles from "./Watch.module.scss";
import classNames from "classnames";
import BuyButton from "./BuyButton";
import * as marked from "marked";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Excerpt } from "../../helpers/Excerpt";

marked.setOptions({
  renderer: new marked.Renderer(),
  pedantic: false,
  gfm: true,
  tables: true,
  breaks: false,
  sanitize: true,
  smartLists: true,
  smartypants: false,
  xhtml: false
});

const Info = ({ video, dialog, closeHandler, onBuyClick, headerStyle }) => {
  const buttonClasses = classNames(styles.details, dialog ? styles.detailOpen : "");

  return (
    <div className={buttonClasses}>
      <div className={classNames(styles.row, !dialog && styles.collapsed)}>
        <div className={styles.infoWrapper} onClick={() => closeHandler(!dialog)}>
          {video.product && video.product.images && video.product.images[0] && (
            <img
              className={styles.image}
              src={video.product.images[0].url}
              alt={video.product.name}
            />
          )}
          <div className={styles.productName}>
            <h4 style={headerStyle}>{Excerpt(video.product.name, 55)}</h4>
          </div>
        </div>

        <div className={styles.checkout}>
          <BuyButton
            price={
              video.product.isDonation ? (
                "Donate"
              ) : (
                <>
                  <span className={styles.buyNow}>{"Buy Now "}</span>
                  <span>
                    {`${Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD"
                    }).format(video.product.price)}`}
                  </span>
                </>
              )
            }
            video={video}
            onClick={onBuyClick}
          />
        </div>
      </div>
    </div>
  );
};

function VideoDetailPanel({ video, headerStyle, textStyle, onBuyClick }) {
  const [dialog, setDialog] = useState(false);

  return (
    <div>
      <Info
        video={video}
        dialog={dialog}
        closeHandler={setDialog}
        onBuyClick={onBuyClick}
        headerStyle={headerStyle}
      />

      <Drawer
        className={classNames(styles.mediumDetail)}
        isOpen={dialog}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        hasBackdrop={true}
        position={Position.BOTTOM}
        onClose={() => setDialog(false)}
        size={"80%"}
      >
        <Info
          video={video}
          dialog={dialog}
          closeHandler={setDialog}
          onBuyClick={onBuyClick}
          headerStyle={headerStyle}
        />
        <Icon
          icon="cross"
          iconSize={20}
          onClick={() => setDialog(false)}
          className={styles.closeButton}
        />
        <div className={styles.info}>
          <div className={styles.row}>
            <div className={styles.videoInfo}>
              <Carousel
                showArrows={true}
                showThumbs={false}
                swipeable={true}
                width={window.innerWidth > 500 ? "350px" : ""}
                className={styles.carousel}
              >
                {video.product &&
                  video.product.images &&
                  video.product.images.map((image, index) => (
                    <div key={`image-{index}`}>
                      <img src={image.url} alt="" />
                    </div>
                  ))}
              </Carousel>

              <div className={classNames(styles.segment, styles.flex1)}>
                <div className={styles.basicInfo}>
                  <h4 style={headerStyle}>{video.product.name}</h4>
                </div>

                <div
                  style={textStyle}
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: marked(video.product.description) }}
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default VideoDetailPanel;
