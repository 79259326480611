import React, { useState } from "react";

import { TwoColumnPage } from "../../layouts/TwoColumnPage";
import LoginExplanation from "./LoginExplanation";
import LoginForm from "./LoginForm";

import useAuthState from "../../hooks/useAuthState";
import { withRouter } from "react-router-dom";

import LinkHelper from "../../helpers/LinkHelper";

function Login({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const { login } = useAuthState();

  const disabled = email === "" || password === "";

  const linkHelper = new LinkHelper();

  const handleLogin = async e => {
    e.preventDefault();

    try {
      await login(email, password);
      history.push(linkHelper.redirectParam || "/");
    } catch (err) {
      if (err.message === "invalid_credentials") {
        setError({
          title: "Invalid username/email or password.",
          message: "Please check your login credentials and try again."
        });
      } else {
        throw err;
      }
    }
  };

  return (
    <TwoColumnPage title="Login">
      <LoginExplanation />
      <LoginForm
        email={email}
        password={password}
        error={error}
        disabled={disabled}
        onEmailChange={e => setEmail(e.target.value)}
        onPasswordChange={e => setPassword(e.target.value)}
        onSubmit={e => handleLogin(e)}
      />
    </TwoColumnPage>
  );
}

export default withRouter(Login);
