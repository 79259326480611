import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { GetNotificationSettings } from "./queries";
import { UpdateEmailPreferences } from "./mutations";

import { getEmailPreferences } from "./helpers";

export const usePreferences = token => {
  const history = useHistory();
  const [updateEmailPreferences] = useMutation(UpdateEmailPreferences, {
    refetchQueries: [{ query: GetNotificationSettings, variables: token }]
  });

  const { loading, data } = useQuery(GetNotificationSettings, {
    variables: { token }
  });

  const [preferences, setPreferences] = useState(data ? getEmailPreferences(data) : undefined);

  useEffect(() => {
    if (!loading && !data?.accounts?.length) {
      history.push("/login");
    }

    setPreferences(getEmailPreferences(data));
  }, [data, loading]);

  const onPreferenceChange = async (name, event) => {
    const updatedPreferences = preferences;
    updatedPreferences[name] = event.target.checked;
    setPreferences(updatedPreferences);

    await updateEmailPreferences({
      variables: {
        preferences: JSON.stringify(updatedPreferences),
        token
      }
    });
  };

  return { loading, data, preferences, onPreferenceChange };
};
