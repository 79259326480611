import React from "react";
import { Button, Popover, Classes, Position, Drawer } from "@blueprintjs/core";
import ShareSheet from "./ShareSheet";
import styles from "./Share.module.scss";

function Share({ video, isLargeScreen, componentName, onDrawerClose, setDialog }) {
  return (
    <>
      {isLargeScreen ? (
        <Popover
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
          position={Position.TOP_RIGHT}
          isOpen={componentName === "Share"}
        >
          <Button
            minimal
            icon="share"
            className={styles.withColorHover}
            onClick={() => setDialog("Share")}
          >
            Share
          </Button>
          <ShareSheet video={video} closeHandler={onDrawerClose} />
        </Popover>
      ) : (
        <Drawer
          position={Position.BOTTOM}
          className={styles.drawer}
          isOpen={componentName === "Share"}
          onClose={onDrawerClose}
        >
          <ShareSheet video={video} closeHandler={onDrawerClose} />
        </Drawer>
      )}
    </>
  );
}

export default Share;
