import React from "react";
import styles from "./Player.module.scss";
import Icon from "../../atoms/Icon/Icon";
import moment from "moment";

function VideoSchedule({ scheduleStartTime }) {
  return (
    <div className={styles.player}>
      <div className={styles.liveStreamTimer}>
        <Icon size={80} name="time" color={"#ffffff"} />
        <div className={styles.info}>
          Your video will start on {moment(scheduleStartTime).format("MMM D, YYYY H:MM:SS")}
        </div>
      </div>
    </div>
  );
}

export default VideoSchedule;
