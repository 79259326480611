import React from "react";
import Helmet from "react-helmet";
import styles from "./TwoColumnPage.module.scss";

function TwoColumnPage({ title, children }) {
  if (children.length !== 2) {
    throw new Error("TwoColumnPage requires exactly two children");
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={styles.root}>
        <div className={styles.gridContainer}>
          <div className={styles.leftGridItem}>{children[0]}</div>
          <div className={styles.rightGridItem}>{children[1]}</div>
        </div>
      </div>
    </>
  );
}

export default TwoColumnPage;
