import React from "react";
import styles from "./Watch.module.scss";
import { Excerpt } from "../../helpers/Excerpt";

function VideoHeader({ video, preference, headerStyle }) {
  const ownerName = video.owner ? video.owner.name : "";
  const ownerPicture = video.owner && video.owner.picture;
  const logo = preference && preference.brandLogo;
  const isLargeScreen = window.innerWidth > 1100;

  return (
    <div className={styles.header}>
      <img className={styles.picture} src={logo ? logo : ownerPicture} alt={ownerName} />
      <div>
        <h1 style={headerStyle} className={styles.heading1}>
          {isLargeScreen ? Excerpt(video.name, 180) : Excerpt(video.name)}
        </h1>
        <label style={headerStyle}>{ownerName}</label>
      </div>
    </div>
  );
}

export default VideoHeader;
