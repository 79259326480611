import React, { useState, useRef, useEffect } from "react";
import { Button, Tooltip } from "@blueprintjs/core";
import styles from "./Player.module.scss";
import { convertSecondsFormat } from "./helpers";

function VideoControls({
  playing,
  muted,
  timeSpent,
  duration,
  onTogglePlay,
  onToggleMute,
  onProgressChange,
  onFullscreen
}) {
  const tooltipRef = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(muted);

  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipRef.current && event.target.textContent !== "volume-off") {
        setTooltipVisible(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipRef]);

  function onMuteClick() {
    setTooltipVisible(false);
    onToggleMute();
  }

  return (
    <div className={styles.controls}>
      <Button
        minimal
        icon={playing ? "pause" : "play"}
        onClickCapture={onTogglePlay}
        style={{ marginLeft: -10, marginRight: 5 }}
      />
      {convertSecondsFormat(timeSpent)}
      <div className={styles.videoProgress}>
        <progress
          className={styles.bar}
          max={parseFloat(duration)}
          value={parseFloat(timeSpent)}
          min="0"
        ></progress>
        <input
          className={styles.progressBar}
          type="range"
          min="0"
          max={parseFloat(duration)}
          value={parseFloat(timeSpent)}
          step="0.1"
          onInput={e => onProgressChange(e.target.value)}
        />
      </div>

      {convertSecondsFormat(duration)}
      <Tooltip isOpen={tooltipVisible} ref={tooltipRef} content="Tap to unmute the video.">
        <Button
          minimal
          icon={muted ? "volume-off" : "volume-up"}
          onClick={onMuteClick}
          style={{ margin: "0px 5px" }}
        />
      </Tooltip>
      <Button minimal icon="fullscreen" onClick={onFullscreen} style={{ marginRight: -10 }} />
    </div>
  );
}

export default VideoControls;
