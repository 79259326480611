import gql from "graphql-tag";

export const CreateOrder = gql`
  mutation CreateOrder(
    $order: OrderInput!
    $address: AddressInput
    $employerAddress: AddressInput
  ) {
    createOrder(order: $order, address: $address, employerAddress: $employerAddress) {
      order {
        id
        linkingToken
      }
      stripePaymentIntentSecret
    }
  }
`;

export const CompleteOrder = gql`
  mutation CompleteOrder($orderId: String!) {
    completeOrder(orderId: $orderId) {
      order {
        id
      }
    }
  }
`;
