import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useResponsive } from "react-hooks-responsive";

import { Switch } from "react-router-dom";
import { Tabs, Tab } from "@blueprintjs/core";
import { NotificationSettings } from "./NotificationSettings";
import { AddressSettings } from "./AddressSettings";
import { PaymentSettings, EditPaymentSettings } from "./PaymentSettings";
import ProtectedRoute from "../../components/App/ProtectedRoute";

import styles from "./Settings.module.scss";

function Settings() {
  const match = useRouteMatch();
  const history = useHistory();
  const { size } = useResponsive({
    small: 0,
    large: 639
  });

  return (
    <div className={styles.settings}>
      <h1>Settings</h1>
      <Tabs
        className={styles.tabs}
        selectedTabId={match.params.tabId}
        onChange={tabId => history.push(`/settings/${tabId}`)}
        vertical={size === "large"}
        renderActiveTabPanelOnly
      >
        <Tab
          id="credit-card"
          title="Credit Card"
          panel={
            <Switch>
              <ProtectedRoute exact path="/settings/credit-card" component={PaymentSettings} />
              <ProtectedRoute
                exact
                path="/settings/credit-card/edit"
                component={EditPaymentSettings}
              />
            </Switch>
          }
        />
        <Tab
          id="address"
          title="Address"
          panel={
            <Switch>
              <ProtectedRoute exact path="/settings/address" component={AddressSettings} />
            </Switch>
          }
        />
        <Tab id="notifications" title="Notifications" panel={<NotificationSettings />} />
      </Tabs>
    </div>
  );
}

export default Settings;
