import { useState, useEffect, useContext } from "react";
import { useMixpanel } from "react-mixpanel-browser";
import useInputState from "../../../hooks/useInputState";
import useMaskedInputState from "../../../hooks/useMaskedInputState";
import CheckoutHelper from "../../../helpers/CheckoutHelper";
import VideoContext from "../../../context/VideoContext";
import {
  InvalidCardNumberError,
  InvalidCardCvvError,
  InvalidCardExpirationError,
  InvalidCardholderNameError,
  InvalidCardError
} from "../../../helpers/StripeHelper";
import stripeHelper from "../../../helpers/StripeHelper";
import usePreviousValue from "../../../hooks/usePreviousValue";

export function useStripeBillingCheckoutForm({
  data,
  dispatch,
  increaseStep,
  name,
  video,
  product
}) {
  const mixpanel = useMixpanel();

  const [{ videoLength, watchTime }] = useContext(VideoContext);
  const [cardNumber, setCardNumber] = useMaskedInputState(data.cardNumber || "", {
    maxLength: 16
  });
  const [cardholderName, setCardHolderName] = useMaskedInputState(data.cardholderName || "", {});
  const [expiration, setExpiration] = useInputState(data.expiration || "");
  const [cvv, setCvv] = useMaskedInputState(data.cvv || "", {
    maxLength: 3
  });
  const [error, setError] = useState((data.errors && data.errors[0]) || {});

  const isCardNumberValid =
    error.name === InvalidCardNumberError ? false : cardNumber.length >= 12 ? true : null;
  const isCardNameValid =
    error.name === InvalidCardholderNameError ? false : !!cardholderName ? true : null;

  const isExpirationDateValid =
    error.name === InvalidCardExpirationError ? false : expiration.length === 5 ? true : null;

  const isCvvValid =
    error.name === InvalidCardCvvError ? false : cvv.length === 3 || cvv.length === 4 ? true : null;

  const isCardError = error.name === InvalidCardError;

  // Remove error on change in field

  const prevCardNumber = usePreviousValue(cardNumber);
  const prevCardholderName = usePreviousValue(cardholderName);
  const prevExpiration = usePreviousValue(expiration);
  const prevCvv = usePreviousValue(cvv);

  useEffect(() => {
    prevCardNumber !== cardNumber && isCardNumberValid === false && setError({});
    cardholderName !== prevCardholderName && isCardNameValid === false && setError({});
    expiration !== prevExpiration && isExpirationDateValid === false && setError({});
    cvv !== prevCvv && isCvvValid === false && setError({});
  }, [
    cardNumber,
    prevCardNumber,
    isCardNumberValid,
    cardholderName,
    prevCardholderName,
    isCardNameValid,
    expiration,
    prevExpiration,
    isExpirationDateValid,
    cvv,
    prevCvv,
    isCvvValid
  ]);

  const onNext = async () => {
    setError({});

    const data = {
      cardNumber,
      cardholderName,
      cvv,
      expiration
    };
    try {
      const response = await stripeHelper.createTokenFromCard(data);
      const body = await response.json();

      const token = body.id;
      const last4 = body.card.last4;
      const brand = body.card.brand;

      data.token = token;
      data.last4 = last4;
      data.brand = brand;

      dispatch({ type: "fulfilled", name, data });
      CheckoutHelper.trackBillingInfoProvided(
        {
          video,
          product,
          watchTime,
          videoLength
        },
        {
          mixpanel
        }
      );
      increaseStep();
    } catch (err) {
      setError(err);
    }
  };

  return {
    cardNumber,
    setCardNumber,
    cardholderName,
    setCardHolderName,
    expiration,
    setExpiration,
    cvv,
    setCvv,
    error,
    isCardNumberValid,
    isCardNameValid,
    isExpirationDateValid,
    isCvvValid,
    isCardError,
    isFormValid: isCardNumberValid && isCardNameValid && isExpirationDateValid && isCvvValid,
    onNext
  };
}

export function useNmiBillingCheckoutForm({ data, dispatch, increaseStep, name, video, product }) {
  const mixpanel = useMixpanel();
  const [{ videoLength, watchTime }] = useContext(VideoContext);
  const [cardholderName, setCardHolderName] = useInputState(data.cardholderName || "");
  const [formLoading, setFormLoading] = useState(true);
  const [cardNumberError, setCardNumberError] = useState({ error: null, message: "" });
  const [cardNameError, setCardNameError] = useState({ error: null, message: "" });
  const [expirationDateError, setExpirationDateError] = useState({ error: null, message: "" });
  const [cvvError, setCvvError] = useState({ error: null, message: "" });
  const [cardError, setCardError] = useState({ error: null, message: "" });
  const [cardDetails, setCardDetails] = useState();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  useEffect(() => {
    if (!!cardDetails) {
      dispatch({ type: "fulfilled", name, data: { ...cardDetails, cardholderName } });
      CheckoutHelper.trackBillingInfoProvided(
        {
          video,
          product,
          watchTime,
          videoLength
        },
        {
          mixpanel
        }
      );
      increaseStep();
    }
  }, [cardDetails]);

  useEffect(() => {
    if (!!cardholderName) {
      setCardNameError({ error: false, message: "" });
    } else if (!cardholderName && cardNameError.error === false) {
      setCardNameError({ error: true, message: "Name is a required field" });
    }
  }, [cardholderName]);

  useEffect(() => {
    var script = document.createElement("script");
    script.src = "https://secure.safewebservices.com/token/Collect.js";
    script.setAttribute("data-tokenization-key", product.owner.tokenizationKey);
    document.head.appendChild(script);

    script.onload = () => {
      window.CollectJS.configure({
        variant: "inline",
        styleSniffer: true,
        tokenizationKey: product.owner.tokenizationKey,
        fieldsAvailableCallback: () => {
          setFormLoading(false);
        },
        callback: resp => {
          const token = resp.token;
          const last4 = resp.card.number.substr(resp.card.number.length - 4);
          const brand = resp.card.type;
          const val = { last4, brand, token };
          setCardDetails(val);
          setIsSubmitLoading(false);
        },
        validationCallback: (fieldName, valid, message) => {
          switch (fieldName) {
            case "ccnumber":
              setCardNumberError({ error: !valid, message: (!valid && message) || "" });
              break;
            case "ccexp":
              setExpirationDateError({ error: !valid, message: (!valid && message) || "" });
              break;
            case "cvv":
              setCvvError({ error: !valid, message: (!valid && message) || "" });
              break;
            default:
              !valid && setCardError({ error: !valid, message: (!valid && message) || "" });
          }
        },
        customCss: {
          height: "40px",
          "line-height": "40px",
          "font-size": "16px",
          outline: "none",
          border: "none",
          "border-radius": "3px",
          background: "#ffffff",
          "font-weight": "400",
          color: "#182026"
        },
        fields: {
          ccnumber: {
            placeholder: "4242 4242 4242 4242",
            selector: "#ccnumber"
          },
          ccexp: {
            placeholder: "MM/YY",
            selector: "#ccexp"
          },
          cvv: {
            placeholder: "CVV",
            selector: "#cvv"
          }
        }
      });
    };
  }, []);

  const onNext = async () => {
    setIsSubmitLoading(true);
    window.CollectJS.startPaymentRequest();
  };

  return {
    cardNumberError,
    cardNameError,
    expirationDateError,
    cvvError,
    cardError,
    cardholderName,
    setCardHolderName,
    onNext,
    formLoading,
    isSubmitLoading,
    submitDisabled:
      cardNameError.error !== false ||
      cardNumberError.error !== false ||
      cvvError.error !== false ||
      expirationDateError.error !== false
  };
}
