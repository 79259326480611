import { useState } from "react";

const useMaskedInputState = (
  initialState,
  { regex = /[\s\S]*/, maxLength = 50, convertToLowerCase = false }
) => {
  const [value, setValue] = useState(initialState);
  const onValueChange = e => {
    const val = e && e.target ? e.target.value : e;
    ((val && regex.test(val)) || val === "") &&
      val.length <= maxLength &&
      setValue(convertToLowerCase ? val.toLowerCase() : val);
  };
  return [value, onValueChange];
};

export default useMaskedInputState;
