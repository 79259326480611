import React from "react";
import styles from "./VerifiedPassword.module.scss";
import { Dialog } from "@blueprintjs/core";

export default function VerifiedPassword() {
  return (
    <>
      <Dialog isOpen style={{ width: 350 }}>
        <div className={styles.purchaseComplete}>
          <img
            src={require("../../molecules/PurchaseCompleteDialog/stars.svg")}
            className={styles.stars}
            alt=""
          />

          <h1>Your password has been updated.</h1>

          <p>Your password has been updated and you're ready to continue using Video Squirrel.</p>
        </div>
      </Dialog>
    </>
  );
}
