import React from "react";
import styles from "./Player.module.scss";
import classNames from "classnames";
import { Icon } from "@blueprintjs/core";

function VideoHud({ visible, icon, onTogglePlay }) {
  return (
    <div
      onClickCapture={onTogglePlay}
      className={classNames(styles.hud, { [styles.visible]: visible })}
    >
      <Icon icon={icon} color="#FFF" iconSize={30} />
    </div>
  );
}

export default VideoHud;
