import gql from "graphql-tag";

export const GetNotificationSettings = gql`
  query GetNotificationSettings($token: String) {
    accounts(emailPreferenceToken: $token) {
      id
      emailPreferences
    }
  }
`;
