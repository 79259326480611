import React from "react";

import styles from "./Link.module.scss";

function Link() {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectTo = urlParams.get("to") || "";
  const link = redirectTo.replace(
    "menu/opportunities/partnered",
    "menu/opportunities/explore-opportunities"
  );
  const redirectUrl = `zipline://${link}`;

  return (
    <div className={styles.linkPage}>
      <p className={styles.title}>Hang tight while we redirect you to the app.</p>
      <p>
        If you don't have the app, we would encourage you to download it for the best native
        experience.
      </p>
      <p>
        Not working? <a href={redirectUrl}>Click Here</a>.
      </p>

      <div className={styles.buttonContainer}>
        <a href="https://itunes.apple.com/US/app/id1449533480">
          <img alt="App Store" src={require("./app_store.svg")} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.zipline.native">
          <img alt="Play Store" src={require("./play_store.svg")} />
        </a>
      </div>
      <div
        className={styles.illustrationContainer}
        style={{ backgroundImage: require("./redirect_car.svg") }}
      />
    </div>
  );
}

export default Link;
