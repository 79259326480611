import * as React from "react";
import ReceiptWrapper from "./ReceiptWrapper";
import { Tag, Spinner, Callout, Intent } from "@blueprintjs/core";
import { useQuery } from "@apollo/react-hooks";
import { useRouteMatch } from "react-router-dom";
import { GET_ORDER_BY_ID } from "./queries";
import qs from "query-string";
import moment from "moment";
import { getLinks, getVariantOptionValues } from "./helpers";
import Icon from "../../atoms/Icon/Icon";
import styles from "./Receipt.module.scss";

export default function Receipt() {
  const { params } = useRouteMatch();
  const viewingToken = qs.parse(window.location.search).token;

  const { loading, data, error } = useQuery(GET_ORDER_BY_ID, {
    variables: { id: params.orderId, viewingToken }
  });

  if (loading) {
    return (
      <ReceiptWrapper noPadding>
        <Spinner />
      </ReceiptWrapper>
    );
  }

  if (error) {
    return (
      <ReceiptWrapper noPadding>
        <Callout icon="error" intent={Intent.DANGER} title="We couldn't find your receipt.">
          We're sorry, we had trouble finding your receipt. Please double check your receipt link.
          If you continue to have troubles, contact Video Squirrel Support.
        </Callout>
      </ReceiptWrapper>
    );
  }

  const orderData = data.orders[0];
  const { supportEmail, name, policies } = orderData.product.owner;
  const sellerLinks = getLinks({ policies, supportEmail, name });
  const variantValues = getVariantOptionValues(orderData.variantOptions);

  return (
    <ReceiptWrapper>
      <div className={styles.sellerPicture}>
        <img alt={orderData.product.owner.name} src={orderData.product.owner.picture} />
      </div>
      <div className={styles.receiptRow}>
        <img
          className={styles.productImage}
          src={orderData.product.images[0].url}
          alt={orderData.product.name}
        />

        <div className={styles.purchaseInfo}>
          <div className={styles.orderNumber}>
            <p>#{orderData.id}</p>
          </div>

          <h1>
            {orderData.product.isDonation
              ? "Thank you for your Donation!"
              : "Thank you for your Purchase!"}
          </h1>

          <div className={styles.infoText}>
            <Icon name="info-sign" size={16} color={"#909BAD"} />
            The charge on your card will show as Video Squirrel
          </div>

          <div className={styles.nameAndTags}>
            <h4 className={styles.productName}>{orderData.product.name}</h4>
            <div className={styles.tags}>
              {variantValues.map((value, index) => (
                <Tag key={index} minimal>
                  {value}
                </Tag>
              ))}
            </div>
          </div>

          <div className={styles.purchaseDetails}>
            <div className={styles.itemDescription}>
              <h5>Item Description</h5>
              <p>{orderData.product.description}</p>
            </div>

            <div className={styles.quantity}>
              <h5>Qty.</h5>
              <p>{orderData.quantity}</p>
            </div>

            <div className={styles.amount}>
              <h5>Amount</h5>
              <p>
                {Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  orderData.amount
                )}
              </p>
            </div>
          </div>

          <div className={styles.purchaseDetails}>
            <div className={styles.dateOfPurchase}>
              <h5>Date of {orderData.product.isDonation ? "Donation" : "Purchase"}</h5>
              <p>{moment(new Date(orderData.createdAt)).format("MMMM D, YYYY")}</p>
            </div>

            <div className={styles.methodOfPayment}>
              <h5>Method of Payment</h5>
              <p>
                {orderData.card} ending in {orderData.last4}
              </p>
            </div>
          </div>
          <div className={styles.purchaseDetails}>
            <div className={styles.customerNote}>
              <h5>Order Note</h5>
              <p>{orderData.customerNote}</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.receiptRow}>
        {!orderData.product.isDonation && (
          <div className={styles.questions}>
            <h5>Questions about your order?</h5>
            <p>
              You can contact the seller for support. If you need more assistance, please contact
              Video Squirrel Support. Thank you!
            </p>
          </div>
        )}
        <div className={styles.policies}>
          {sellerLinks.length > 0 && (
            <>
              <h5>{orderData.product.owner.name}'s Links</h5>

              {sellerLinks.map((chunk, index) => (
                <ul key={index}>
                  {chunk.map(link =>
                    link.isEmail ? (
                      <li key={index}>
                        <a href={`mailto:${link.url}`}>{link.name}</a>
                      </li>
                    ) : (
                      <li key={link.name}>
                        <a target="_blank" rel="noopener noreferrer" href={link.url}>
                          {link.name}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              ))}
            </>
          )}
        </div>
      </div>
    </ReceiptWrapper>
  );
}
