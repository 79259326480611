export const getEmailPreferences = data => {
  const emailPreferences =
    data && data.accounts && data.accounts[0] ? JSON.parse(data.accounts[0].emailPreferences) : {};

  return {
    "brand-application-alert":
      typeof emailPreferences["brand-application-alert"] !== "undefined"
        ? emailPreferences["brand-application-alert"]
        : true,
    "brand-low-product":
      typeof emailPreferences["brand-low-product"] !== "undefined"
        ? emailPreferences["brand-low-product"]
        : true,
    "brand-product-out":
      typeof emailPreferences["brand-product-out"] !== "undefined"
        ? emailPreferences["brand-product-out"]
        : true,
    "brand-purchase-alert":
      typeof emailPreferences["brand-purchase-alert"] !== "undefined"
        ? emailPreferences["brand-purchase-alert"]
        : true,
    "brand-video-created":
      typeof emailPreferences["brand-video-created"] !== "undefined"
        ? emailPreferences["brand-video-created"]
        : true,
    "influencer-application-accept":
      typeof emailPreferences["influencer-application-accept"] !== "undefined"
        ? emailPreferences["influencer-application-accept"]
        : true,
    "influencer-application-reject":
      typeof emailPreferences["influencer-application-reject"] !== "undefined"
        ? emailPreferences["influencer-application-reject"]
        : true,
    "influencer-low-product":
      typeof emailPreferences["influencer-low-product"] !== "undefined"
        ? emailPreferences["influencer-low-product"]
        : true,
    "influencer-opportunity-removed":
      typeof emailPreferences["influencer-opportunity-removed"] !== "undefined"
        ? emailPreferences["influencer-opportunity-removed"]
        : true,
    "influencer-product-out":
      typeof emailPreferences["influencer-product-out"] !== "undefined"
        ? emailPreferences["influencer-product-out"]
        : true,
    "influencer-purchase-alert":
      typeof emailPreferences["influencer-purchase-alert"] !== "undefined"
        ? emailPreferences["influencer-purchase-alert"]
        : true
  };
};

export const getPreferenceDetails = key => {
  switch (key) {
    case "brand-application-alert":
      return {
        label: "Application Alert",
        description:
          "When an influencer applies to sell one of your products, you will receive an email notification."
      };
    case "brand-low-product":
      return {
        label: "Low Stock Alert",
        description:
          "If the quantity remaining of any of your products falls below 30, you will receive an email notification."
      };
    case "brand-product-out":
      return {
        label: "Out of Stock Alert",
        description:
          "If one of your products goes out of stock, you will receive an email notification."
      };
    case "brand-purchase-alert":
      return {
        label: "Purchase Alert",
        description:
          "When a customer purchases your product from a video you created, you will receive an email notification."
      };
    case "brand-video-created":
      return {
        label: "Video Created Alert",
        description:
          "When an influencer creates a video for your product, you will receive an email notification."
      };
    case "influencer-application-accept":
      return {
        label: "Application Accepted Alert",
        description:
          "When a brand approves your application to sell their product, you will receive an email notification."
      };
    case "influencer-application-reject":
      return {
        label: "Application Declined Alert",
        description:
          "When a brand declines your application to sell their product, you will receive an email notification."
      };
    case "influencer-low-product":
      return {
        label: "Low Stock Alert",
        description:
          "If the quantity remaining of a product you’re partnered with falls below 30, you will receive an email notification."
      };
    case "influencer-product-out":
      return {
        label: "Out of Stock Alert",
        description:
          "If a product you’re partnered with goes out of stock, you will receive an email notification."
      };
    case "influencer-opportunity-removed":
      return {
        label: "Opportunity Removed",
        description:
          "A product you're partnered with was discontinued, you will receive an email notification."
      };
    case "influencer-purchase-alert":
      return {
        label: "Purchase Alert",
        description:
          "If a customer purchases a product you’re partnered with through a video you created, you will receive an email notification."
      };
    case "":
      return {};
    default:
      return {
        label: "",
        description: ""
      };
  }
};
