import {useContext, useState} from "react";
import { useMixpanel } from "react-mixpanel-browser";
import useInputState from "../../../hooks/useInputState";
import VideoContext from "../../../context/VideoContext";
import CheckoutHelper from "../../../helpers/CheckoutHelper";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const getStates = gql`
  query getStates {
    states {
      id
      name
    }
  }
`;

export function usePoliticalCheckout({ data, dispatch, increaseStep, name, video, product }) {
  const mixpanel = useMixpanel();

  const employerAddress = data && data.employerAddress;
  const [{ videoLength, watchTime }] = useContext(VideoContext);

  const [employer, setEmployer] = useInputState(data ? data.employer : "");
  const [occupation, setOccupation] = useInputState(data ? data.occupation : "");
  const [retired, setRetired] = useState(data && data.retired);

  const [streetName, setStreetName] = useInputState(employerAddress ? employerAddress.streetName : "");
  const [city, setCity] = useInputState(employerAddress ? employerAddress.city : "");
  const [addressState, setAddressState] = useInputState(employerAddress ? employerAddress.addressState : "");
  const [postalCode, setPostalCode] = useInputState(employerAddress ? employerAddress.postalCode : "");

  const { data: statesData } = useQuery(getStates);
  const statesList = [
    { label: "State", value: "", disabled: true },
    ...((statesData && statesData.states.map(({ id, name }) => ({ value: id, label: name }))) || [])
  ];
  const isValidEmployerAddress = !!streetName && !!city && !!addressState && !!postalCode;

  function handleRetirementChange(e) {
    setRetired(e.target.checked);

    if (e.target.checked) {
      setEmployer("Retired");
      setOccupation("Retired");
      setStreetName("")
      setCity("")
      setPostalCode("")
      setAddressState("")
    } else {
      setEmployer("");
      setOccupation("");
    }
  }

  const onNextClick = e => {
    e.preventDefault();
    const employerAddress = {
      streetName: streetName,
      city: city,
      addressState: addressState,
      postalCode: postalCode
    };
    dispatch({ type: "fulfilled", name, data: { employer, occupation, ...(isValidEmployerAddress ? {employerAddress} : {}), retired } });
    CheckoutHelper.trackPoliticalInfoProvided(
      { video, product, watchTime, videoLength },
      { mixpanel }
    );
    increaseStep();
  };

  return {
    employer,
    setEmployer,
    occupation,
    setOccupation,
    streetName,
    setStreetName,
    city,
    setCity,
    addressState,
    setAddressState,
    postalCode,
    setPostalCode,
    statesList,
    retired,
    handleRetirementChange,
    isFormValid:
      !!employer &&
      !!occupation &&
      (retired || isValidEmployerAddress),
    onNextClick
  };
}
