import React from "react";

import { FormGroup, InputGroup, Callout, Intent } from "@blueprintjs/core";
import { AccountForm } from "../../molecules/AccountForms";
import { Link } from "react-router-dom";

import LinkHelper from "../../helpers/LinkHelper";

import styles from "./LoginForm.module.scss";

function LoginForm({
  email,
  password,
  error,
  disabled,
  onEmailChange,
  onPasswordChange,
  onSubmit
}) {
  const linkHelper = new LinkHelper();

  const bottomLinkComponent = (
    <div className={styles.registerWrapper}>
      <p className={styles.registerText}>
        Don't have a Video Squirrel account?{" "}
        <Link to={linkHelper.appendRedirect("/register")}>Sign up</Link>
      </p>
    </div>
  );

  return (
    <AccountForm
      headerText="Sign in to Your Video Squirrel Account"
      buttonText="Sign In!"
      bottomLinkComponent={bottomLinkComponent}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      {error && (
        <Callout
          intent={Intent.WARNING}
          title={error.title}
          style={{ marginBottom: "1em" }}
          icon={null}
        >
          {error.message}
        </Callout>
      )}

      <FormGroup label="Email Address or Username" labelFor="email-input">
        <InputGroup
          id="email-input"
          autoCapitalize="none"
          autoCorrect="false"
          value={email}
          onChange={onEmailChange}
        />
      </FormGroup>

      <FormGroup
        label="Password"
        labelFor="password-input"
        helperText={<Link to={linkHelper.appendRedirect("/forgot-password")}>Forgot Password</Link>}
      >
        <InputGroup
          id="password-input"
          type="password"
          autoCapitalize="none"
          autoCorrect="false"
          value={password}
          onChange={onPasswordChange}
        />
      </FormGroup>
    </AccountForm>
  );
}

export default LoginForm;
