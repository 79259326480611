export const getVariants = selectedValues =>
  selectedValues.reduce((acc, value) => (acc ? `${acc}, ${value.name}` : value.name), "");

export const formatAddress = ({
  suffix,
  firstName,
  middleInitial,
  lastName,
  streetName,
  city,
  addressState,
  postalCode
}) => {
  return {
    suffix,
    firstName,
    middleInitial,
    lastName,
    street1: streetName,
    street2: "",
    city: city,
    stateId: addressState,
    postalCode: postalCode
  };
};

export const parseNetworkError = err => {
  return err.networkError
    ? err.networkError.result.errors[0].message
    : "Sorry! Something went wrong while placing your order. Please try again later.";
};
