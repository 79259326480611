import React from "react";
import { Popover, Position, Classes, Icon, Button } from "@blueprintjs/core";
import styles from "./BillingCheckout.module.scss";

export default function CvvInfoPopover() {
  return (
    <Popover
      minimal
      position={Position.BOTTOM_RIGHT}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
    >
      <Button minimal small icon="info-sign" tabIndex={-1} />
      <div className={styles.cvvInfo}>
        <Icon icon="info-sign" iconSize={15} />
        <p>Fill in the CVV number provided on the back of your credit card.</p>
      </div>
    </Popover>
  );
}
