import React from "react";
import circle from "../../assets/signIn.svg";
import { LeftExplanation } from "../../molecules/LeftExplanation";

function LoginExplanation() {
  const explanation =
    "Are you ready to create and discover new opportunities, track your " +
    "sales and cash out on your progress? We bet you are.";
  return <LeftExplanation icon={circle} title="Welcome Back!" explanation={explanation} />;
}

export default LoginExplanation;
