import moment from "moment";

export function buildVideoProperties({
  video,
  videoState = { watchTime: "0", videoLength: "0" },
  ...props
}) {
  const { id, name, product, owner } = video;

  return {
    VideoId: id,
    VideoName: name,
    InfluencerId: owner.id,
    InfluencerName: owner.name,
    ProductId: product && product.id,
    ProductName: product && product.name,
    OwnerId: product && product.owner && product.owner.id,
    OwnerName: product && product.owner && product.owner.name,
    WatchTime: videoState.watchTime,
    VideoLength: videoState.videoLength,
    ...props
  };
}
export function convertSecondsFormat(secs) {
  let format = secs >= 3600 ? "HH:mm:ss" : "mm:ss";
  const dummyDate = "1900-01-01 00:00:00";
  return moment(dummyDate)
    .add(secs, "seconds")
    .format(format);
}
