import React from "react";
import { LeftExplanation } from "../../molecules/LeftExplanation";
import circle from "../../assets/registrationStep1.png";

function RegisterExplanation() {
  const explanation =
    "The contact email will receive ALL updates and notifications regarding your Video Squirrel account. " +
    " Choose wisely.";

  return (
    <LeftExplanation
      icon={circle}
      title="Let’s get your profile ready and filled out."
      explanation={explanation}
    />
  );
}

export default RegisterExplanation;
