import { chunk } from "lodash";

export function getVariantOptionValues(variantOptions) {
  const parsed = JSON.parse(variantOptions);
  if (!parsed) return [];
  return Object.values(parsed);
}

export function getLinks({ policies, website, supportEmail, name }) {
  const { privacyPolicyUrl, termsOfServiceUrl, refundPolicyUrl } = policies;

  const activePolicies = [
    privacyPolicyUrl && { name: "Privacy Policy", url: privacyPolicyUrl, isEmail: false },
    termsOfServiceUrl && { name: "Terms of Service", url: termsOfServiceUrl, isEmail: false },
    refundPolicyUrl && { name: "Refund Policy", url: refundPolicyUrl, isEmail: false },
    website && { name: "Website", url: website, isEmail: false },
    supportEmail && { name: `Contact ${name}`, url: supportEmail, isEmail: true }
  ].filter(p => !!p);

  return chunk(activePolicies, 2);
}
