import gql from "graphql-tag";

export const TagAccountType = gql`
  mutation TagAccountType($type: AccountType!) {
    tagAccountType(accountType: $type)
  }
`;

export const UpdateAccountInfo = gql`
  mutation UpdateAccountInfo($account: UpdateAccountInput!) {
    updateAccountInfo(account: $account)
  }
`;
