import React, { useState } from "react";
import StepNavigator from "./StepNavigator";
import {
  useCheckoutState,
  BILLING_PAGE,
  POLITICAL_PAGE,
  SHIPPING_PAGE,
  VARIANT_PAGE,
  REVIEW_PAGE,
  DONATION_PAGE
} from "../../molecules/CheckoutDrawer/CheckoutDrawer";
import {
  BillingCheckout,
  PoliticalCheckout,
  ShippingAddressCheckout,
  VariantCheckout,
  DonationCheckout,
  CheckoutReview
} from "../../pages/Checkout";
import CheckoutStepHeader from "./CheckoutStepHeader";
import styles from "./CheckoutStepNavigator.module.scss";

function getScreen(requirementName) {
  switch (requirementName) {
    case BILLING_PAGE:
      return BillingCheckout;
    case POLITICAL_PAGE:
      return PoliticalCheckout;
    case SHIPPING_PAGE:
      return ShippingAddressCheckout;
    case VARIANT_PAGE:
      return VariantCheckout;
    case REVIEW_PAGE:
      return CheckoutReview;
    case DONATION_PAGE:
      return DonationCheckout;
    default:
      throw Error(`no screen exists for ${requirementName}`);
  }
}

function CheckoutStepNavigator({ closeSidebar }) {
  const [step, setStep] = useState(0);
  const checkout = useCheckoutState();
  const requirements = checkout.requirements ? Object.values(checkout.requirements) : [];

  const sortedRequirements = requirements.sort((a, b) => (a.priority < b.priority ? -1 : 1));
  //Temporary Workaround to access Review Screen
  sortedRequirements.push({ name: "Review" });

  const screens = sortedRequirements.map(requirement => ({
    title: requirement.name,
    screen: getScreen(requirement.name)
  }));
  const currentPage = sortedRequirements[step].name;

  return (
    <div className={styles.checkoutWrapper}>
      {currentPage !== REVIEW_PAGE && (
        <CheckoutStepHeader
          currentStep={step}
          requirements={sortedRequirements}
          setStep={setStep}
        />
      )}
      <StepNavigator
        step={step}
        screens={screens}
        increaseStep={() => setStep(step + 1)}
        decreaseStep={() => setStep(step - 1)}
        setStep={setStep}
        closeSidebar={closeSidebar}
      />
    </div>
  );
}

export default CheckoutStepNavigator;
